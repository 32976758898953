import React from 'react';
import {
  Flex, Text, Image, Box,
} from 'rebass';
import styled from '@emotion/styled';

const STEPS = [
  'Wybierz bon i nominał',
  'Wydrukuj lub pobierz bon w formacie pdf',
  'Zrealizuj bon w punkcie partnerskim',
];

const Wrapper = styled(Box)`
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    :not(:last-of-type) {
      &:after {
        content: ' ';
        width: 55px;
        height: 39px;
        background-image: url(/images/arrow.png);
        top: 50%;
        right: -30px;
        position: absolute;
        transform: translateY(-50%);
      }
    }
  }
`;

class Steps extends React.Component {
  render() {
    const { activeStep } = this.props;
    return (
      <Flex flexDirection="column" mb={[20, 40, 69]}>
        <Text fontSize={25} fontWeight="bold" mb={37}>
          Jak korzystać z aimedo.gift?
        </Text>
        <Flex mx={[10, 10, -37]} flexWrap="wrap">
          {STEPS.map((step, index) => (
            <Wrapper
              textAlign="center"
              width={[1, 1 / 3, 1 / 3]}
              px={[10, 10, 37]}
              mb={37}
              key={index}
            >
              <Image
                src={`/images/step-${index + 1}${activeStep === index + 1 ? '-active' : ''}.svg`}
                mb={30}
                height={100}
              />
              <Text maxWidth={250} fontSize={20} fontWeight={600} mx="auto">
                {step}
              </Text>
            </Wrapper>
          ))}
        </Flex>
      </Flex>
    );
  }
}

export default Steps;
