import React from 'react';
import {
  Box, Image, Flex, Text,
} from 'rebass';
import styled from '@emotion/styled';
import includes from 'lodash/includes';
import Overlay from '../common/components/Overlay';
import { colors } from '../app/theme';

const ListItem = styled(Box)`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
`;

const BonDetailsOverlay = ({
  onClose, details: {
    baseUrl = '', assortmentDescription, partnerDescription, specialOfferDescription,
  },
}) => (
  <Overlay onClose={onClose} p={0}>
    <Flex justifyContent="space-between" sx={{ borderBottom: `5px solid ${colors.lightGray}` }} pt={30}>
      <Text pl={[30, 80]} fontSize={28} py={37} fontWeight={500}>
        Szczegóły bonu
      </Text>
      <Image height={23} src="/images/x.png" sx={{ cursor: 'pointer' }} onClick={onClose} mr={28} />
    </Flex>
    <Flex flexDirection="column" px={[30, 80]} pb={50}>
      {baseUrl && (
        <Text
          as="a"
          fontSize={20}
          target="_blank"
          color="black"
          my={25}
          href={includes(baseUrl, 'http') ? baseUrl : `http://${baseUrl}`}
        >
          {baseUrl.replace('https://', '').replace('http://', '')}
        </Text>
      )}
      {assortmentDescription && (
        <ListItem pt={25} pb={20}>
          <Text color="green" fontSize={20} fontWeight={500} mb={20}>
            ASORTYMENT
          </Text>
          <Text lineHeight="22px"><span dangerouslySetInnerHTML={{ __html: assortmentDescription }} /></Text>
        </ListItem>
      )}
      {partnerDescription && (
        <ListItem pt={25} pb={20}>
          <Text color="green" fontSize={20} fontWeight={500} mb={20}>
            OPIS PARTNERA
          </Text>
          <Text lineHeight="22px"><span dangerouslySetInnerHTML={{ __html: partnerDescription }} /></Text>
        </ListItem>
      )}
      {specialOfferDescription && (
        <ListItem pt={25} pb={20}>
          <Text color="green" fontSize={20} fontWeight={500} mb={20}>
            OFERTA SPECJALNA
          </Text>
          <Text lineHeight="22px"><span dangerouslySetInnerHTML={{ __html: specialOfferDescription }} /></Text>
        </ListItem>
      )}
    </Flex>
  </Overlay>
);

export default BonDetailsOverlay;
