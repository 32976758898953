import React from 'react';
import {
  Text, Flex, Box, Heading,
} from 'rebass';
import styled from '@emotion/styled';
import BaseContainer from './BaseContainer';

const Banner = styled(Flex)`
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    width: 1272px;
    height: 948px;
    right: -250px;
    z-index: 0;
    background: url("/images/banner-background.png") center center no-repeat;
    background-size: cover;
  }
`;

class LayoutWithBanner extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <Box bg="lighterGray">
        <Banner
          flexDirection="column"
          justifyContent="center"
          bg="white"
          py={[40, 70]}
        >
          <BaseContainer>
            <Heading mb={26} fontSize={[40, 50, 70]} fontWeight="bold">aimedo.gift</Heading>
            <Text width={[1, 1 / 2, 1 / 2]} fontSize={[16, 20]} lineHeight="30px">
              Elektroniczny bon podarunkowy, który można wymienić na wybrane bony towarowe w ramach bogatej oferty sklepów i punktów współpracujących.
            </Text>
          </BaseContainer>
        </Banner>
        <BaseContainer>
          {children}
        </BaseContainer>
      </Box>
    );
  }
}

export default LayoutWithBanner;
