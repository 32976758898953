import { Label } from '@rebass/forms';
import React from 'react';
import styled from '@emotion/styled';
import { Box, Text } from 'rebass';
import Error from './Error';

const StyledCheckbox = styled('input')`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 6px;
    border: 1px solid ${props => props.theme.colors.lightGray};
    background: #fff;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    background: url('/images/tick.png') no-repeat center center;
    background-size: contain;
    width: 11px;
    height: 10px;
    left: 8px;
  }
`;

const Checkbox = ({
  input, label, meta, ...props
}) => (
  <Box>
    <StyledCheckbox id={input.name} type="checkbox" {...input} {...props} />
    <Label htmlFor={input.name} mt={10} mb={10} sx={{ alignItems: 'center' }}>
      <Text fontSize={14}>{label}</Text>
    </Label>
    <Error meta={meta} />
  </Box>
);

export default Checkbox;
