import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';
import {
  Card, Text, Button, Flex, Image,
} from 'rebass';
import Steps from '../home/Steps';
import LayoutWithBanner from '../common/layouts/LayoutWithBanner';
import SendVouchersForm from './SendVouchersForm';
import BaseContainer from '../common/layouts/BaseContainer';
import { APP_ROUTES } from '../app/routes';

@inject('orderStore')
@observer
class VouchersRedeemPage extends React.Component {
  @observable showForm = false;

  render() {
    const { orderId } = this.props;
    const { sendPdfToEmail } = this.props.orderStore;
    return (
      <LayoutWithBanner>
        <BaseContainer p={[20, 40, 69]}>
          <Steps activeStep={3} />
          <Card
            p={[50, 100, 140]}
            mb={37}
            textAlign="center"
          >
            {!this.showForm && (
              <>
                <Image src="/images/tick.svg" mb={25} />
                <Text
                  fontSize={28}
                  fontWeight={600}
                  color="green"
                  mb={[30, 50, 100]}
                >
                  Dziękujemy za wybór bonu!
                </Text>
                <Flex justifyContent="center" alignItems={['center', 'stretch']} flexDirection={['column', 'row']}>
                  <Button variant="secondary" width={250} mr={[0, 25]} mb={25} onClick={() => navigate(APP_ROUTES.VOUCHER_HISTORY_BY_ORDER_ID(orderId))}>
                    POBIERZ BONY PDF
                  </Button>
                  <Button variant="secondary" width={250} onClick={() => { this.showForm = true; }} mb={25}>
                    WYŚLIJ BONY NA EMAIL
                  </Button>
                </Flex>
                <Flex mb={25} justifyContent="center" alignItems={['center', 'stretch']} flexDirection={['column', 'row']}>
                  <Button variant="secondary" width={250} mr={[0, 25]} mb={25} onClick={() => navigate(APP_ROUTES.ROOT)}>
                    WYBIERZ KOLEJNY BON
                  </Button>
                  <Button variant="secondary" width={250} mb={25} onClick={() => navigate(APP_ROUTES.ROOT)}>
                    STRONA GŁÓWNA
                  </Button>
                </Flex>
              </>
            )}
            {this.showForm && <SendVouchersForm onSubmit={({ email }) => sendPdfToEmail({ email, orderId })} />}
          </Card>
        </BaseContainer>
      </LayoutWithBanner>
    );
  }
}

export default VouchersRedeemPage;
