import React, { Component } from 'react';
import { Box, Flex, Text } from 'rebass';
import isEmpty from 'lodash/isEmpty';
import Script from 'react-load-script';
import { inject, observer } from 'mobx-react';
import LayoutWithBanner from '../common/layouts/LayoutWithBanner';
import BaseContainer from '../common/layouts/BaseContainer';
import PlacesAutocomplete from './PlacesAutocomplete';
import Map from './Map';
import Loader from '../common/components/Loader';

@inject('mapStore')
@observer
class MapPage extends Component {
  state = {
    isLoaded: false,
    error: null,
    map: {},
  };

  async componentDidMount() {
    const { latitude, longitude } = await this.props.mapStore.fetchCurrentLocation();
    if (latitude && longitude) {
      this.setState({ map: { center: { lat: latitude, lng: longitude }, zoom: 13 } });
      this.props.mapStore.fetchLocations({ lat: latitude, lng: longitude });
    }
  }

  render() {
    const { isLoaded, error, map } = this.state;
    const { data, isLoading: isLoadingLocations } = this.props.mapStore.locations;
    const { fetched, isLoading } = this.props.mapStore.currentPosition;
    return (
      <>
        <Script
          url={process.env.REACT_APP_GOOGLE_MAPS_URL}
          onCreate={() => this.setState({ isLoaded: false })}
          onError={() => this.setState({ error: 'Error' })}
          onLoad={() => this.setState({ isLoaded: true })}
        />
        {error && 'Error'}
        {isLoaded && !error && (
          <LayoutWithBanner>
            <BaseContainer p={[0, 40, isLoading ? 120 : 69]} py={[20, 0]}>
              {isLoading && <Loader />}
              {fetched && (
                <>
                  <PlacesAutocomplete
                    onSubmit={(coords) => {
                      this.setState({ map: { center: { ...coords }, zoom: 13 } });
                    }}
                  />
                  <Flex flexWrap={['wrap', 'nowrap']}>
                    {isLoadingLocations && (
                      <Flex width={[1, 300]} order={[2, 1]}>
                        <Loader />
                      </Flex>
                    )}
                    <Flex
                      order={[2, 1]}
                      flexDirection="column"
                      width={[1, !isEmpty(data) ? 300 : 0]}
                      sx={{
                        height: 400,
                        overflowY: 'scroll',
                      }}
                    >
                      {data.map((location, idx) => (
                        <Box key={`map-location-place-${idx}`} mb={45}>
                          <Text>{location.street}</Text>
                          <Text mb={10}>{`${location.zip} ${location.city}`}</Text>
                          <Text mb="5px" fontSize={14}>
                            Godziny otwarcia w tygodniu:
                            <br />
                            {location.openingHoursWeek}
                          </Text>
                          {location.openingHoursSaturday && (
                            <Text mb="5px" fontSize={14}>
                              Godziny otwarcia w soboty:
                              <br />
                              {location.openingHoursSaturday}
                            </Text>
                          )}
                          {location.openingHoursSunday && (
                            <Text mb="5px" fontSize={14}>
                              Godziny otwarcia w niedziele:
                              <br />
                              {location.openingHoursSunday}
                            </Text>
                          )}
                          <Text
                            mt={10}
                            sx={{ cursor: 'pointer' }}
                            color="green"
                            onClick={() => {
                              this.setState({
                                map: {
                                  center: {
                                    lat: location.latitude,
                                    lng: location.longitude,
                                  },
                                  zoom: 17,
                                },
                              });
                            }}
                          >
                            Pokaż na mapie
                          </Text>
                        </Box>
                      ))}
                    </Flex>
                    <Box width={1} order={[1, 2]} mb={[20, 0]}>
                      <Map
                        containerElement={<div style={{ height: '400px' }} />}
                        mapElement={<div style={{ height: '100%' }} />}
                        {...map}
                      />
                    </Box>
                  </Flex>
                </>
              )}
            </BaseContainer>
          </LayoutWithBanner>
        )}
      </>
    );
  }
}

export default MapPage;
