import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import HamburgerMenu from 'react-hamburger-menu';
import {
  Flex, Box, Image, Text, Button,
} from 'rebass';
import moment from 'moment';
import styled from '@emotion/styled';
import RouterLink from '../RouterLink';
import { APP_ROUTES } from '../../../app/routes';

export const Wrapper = styled(Flex)`
  width: 100%;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.green};
  align-items: center;
  z-index: 250;
  height: 80px;
`;

const MenuItemsContainer = styled(Flex)`
  @media (max-width: 960px) {
    position: fixed;
    top: 80px;
    width: 100%;
    left: 0;
    background-color: ${({ theme }) => theme.colors.green};
    height: ${props => (props.isMobileOpen ? '275px' : '0px')};
    overflow: hidden;
    transition: height 0.25s ease-in;
  }
`;

@inject('authStore', 'userStore')
@observer
class NavBar extends Component {
  ref = React.createRef();
  @observable isMobileOpen = false;

  componentDidMount() {
    document.addEventListener('mouseup', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.isMobileOpen = false;
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.isMobileOpen = false;
    }
  };

  toggleHamburger = () => {
    this.isMobileOpen = !this.isMobileOpen;
  };

  render() {
    const { logout, isAuthenticated } = this.props.authStore;
    const { number, balance, validTo } = this.props.userStore.profile.data;
    return (
      <Wrapper bg="green" ref={this.ref}>
        <Flex px={15} sx={{ maxWidth: 1400, height: '100%' }} width={1} mx="auto" alignItems="center" justifyContent="space-between">
          <RouterLink to={APP_ROUTES.ROOT} height="100%">
            <Flex height="100%">
              <Image height={50} src="/images/white-circles.png" alignSelf="flex-end" />
              <Text ml={10} mr={30} fontSize={20} alignSelf="center">
                aimedo.gift
              </Text>
            </Flex>
          </RouterLink>
          {isAuthenticated && (
            <>
              <Box
                sx={{
                  '@media only screen and (min-width: 960px)': {
                    display: 'none',
                  },
                }}
              >
                <HamburgerMenu
                  isOpen={this.isMobileOpen}
                  menuClicked={this.toggleHamburger}
                  width={20}
                  height={15}
                  strokeWidth={2}
                  rotate={0}
                  color="#fff"
                  borderRadius={0}
                  animationDuration={0.5}
                />
              </Box>
              <MenuItemsContainer as="ul" isMobileOpen={this.isMobileOpen} flexDirection={['column', 'column', 'row']} alignItems="center">
                {number && (
                  <>
                    <Text as="li" fontSize={18} pb={10} pt={[40, 40, 10]} fontWeight={500} mr={[10, 10, 10, 30]} alignSelf="center">
                      {`numer karty: ${number}`}
                    </Text>
                    <Text fontSize={18} py={10} fontWeight={500} mr={[10, 10, 10, 30]} alignSelf="center" as="li">
                      {`saldo: ${balance} zł`}
                    </Text>
                    <Text fontSize={18} py={10} fontWeight={500} mr={[10, 10, 10, 30]} alignSelf="center" as="li">
                      {`data ważności: ${moment.utc(validTo).format('DD.MM.YYYY')}`}
                    </Text>
                  </>
                )}
                <RouterLink
                  to={APP_ROUTES.VOUCHER_HISTORY}
                  fontSize={18}
                  pt={10}
                  pb={[30, 30, 10]}
                  fontWeight={500}
                  mr={[10, 10, 10, 30]}
                  alignSelf="center"
                  as="li"
                >
                  historia: moje bony
                </RouterLink>
                <Button variant="outline" py="8px" px={30} mb={[20, 20, 0]} onClick={logout}>
                  Wyloguj
                </Button>
              </MenuItemsContainer>
            </>
          )}
        </Flex>
      </Wrapper>
    );
  }
}

export default NavBar;
