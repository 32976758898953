import {
  action, observable, computed, when,
} from 'mobx';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import API from '../app/api';
import { API_ROUTES } from '../app/routes';
import userStore from './userStore';

export class OffersStore {
  @observable offers = {
    isLoading: false,
    data: [],
    error: null,
  };

  @action fetchOffers = async () => {
    if (!userStore.isActive) {
      await when(() => userStore.isActive);
    }
    try {
      this.offers.isLoading = true;
      const { data } = await API(API_ROUTES.OFFER);
      this.offers.data = data;
    } catch (e) {
      this.offers.error = e.message;
    } finally {
      this.offers.isLoading = false;
    }
  };

  @computed get carouselItems() {
    return sortBy(this.offers.data, ['carouselPosition']).map(item => ({
      ...pick(item, ['carouselPosition', 'logoUrl']),
      carouselUrl: item.carouselUrl || item.baseUrl,
    }));
  }
}

export default new OffersStore();
