import React from 'react';
import { Flex, Text, Box } from 'rebass';
import styled from '@emotion/styled';
import BaseContainer from '../common/layouts/BaseContainer';

const Styles = styled(Box)`
  h6 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin: 15px 0;
  }
  ol,
  ul {
    list-style: decimal;
    margin-bottom: 5px;
  }
  li {
    line-height: 1.2;
    margin-bottom: 5px;
  }
  strong {
    font-weight: bold;
  }
`;
/* eslint-disable react/jsx-one-expression-per-line */

class CookiesPolicyPage extends React.Component {
  render() {
    return (
      <BaseContainer my={50} px={[20, 35]}>
        <Flex width={1} flexDirection="column">
          <Flex width={1} alignItems="center" flexDirection="column">
            <Text fontWeight="bold" fontSize={24}>
              Polityka Cookies
            </Text>
            <Text fontSize={20} mt={10} mb={20}>
              Poniższa Polityka Cookies określa zasady zapisywania i uzyskiwania dostępu do danych na Urządzeniach Użytkowników
              korzystających z Serwisu do celów świadczenia usług drogą elektroniczną przez Administratora Serwisu.
            </Text>
          </Flex>
          <Styles width={1}>
            <h6>§ 1 Definicje</h6>
            <ul>
              <li>
                <p>
                  <strong>Serwis</strong> - serwis internetowy działający pod adresem{' '}
                  <span id="serwis">
                    <span>www.aimedo.gift</span>
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <strong>Serwis zewnętrzny</strong> - serwis internetowe partnerów, usługodawców lub usługobiorców Administratora
                </p>
              </li>
              <li>
                <p>
                  <strong>Administrator</strong> - firma{' '}
                  <span id="firma">
                    <span>epruf s.a.</span>, prowadząca działalność pod adresem: <span>ul. Zbąszyńska 3, 91-342 Łódź</span>, o nadanym
                    numerze identyfikacji podatkowej (NIP): <span>9471958279</span>, o nadanym numerze REGON: <span>100592925</span>,
                    zarejestrowana w <span>Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi</span> pod numerem KRS: <span>0000319410</span>
                  </span>
                  , świadcząca usługi drogą elektroniczną za pośrednictwem Serwisu oraz przechowująca i uzyskująca dostęp do informacji w
                  urządzeniach Użytkownika
                </p>
              </li>
              <li>
                <p>
                  <strong>Użytkownik</strong> - osba fizyczna, dla której Administrator świadczy usługi drogą elektroniczna za pośrednictwem
                  Serwisu.
                </p>
              </li>
              <li>
                <p>
                  <strong>Urządzenie</strong> - elektroniczne urządzenie wraz z oprogramowaniem, za pośrednictwem, którego Użytkownik
                  uzyskuje dostęp do Serwisu
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies (ciasteczka)</strong> - dane tekstowe gromadzone w formie plików zamieszczanych na Urządzeniu Użytkownika
                </p>
              </li>
            </ul>
            <h6>§ 2 Rodzaje Cookies</h6>
            <ul>
              <li>
                <p>
                  <strong>Cookies wewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przes system
                  teleinformatyczny Serwisu
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies zewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez systemy
                  teleinformatyczne Serwisów zewnętrznych
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies sesyjne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis{' '}
                  <span id="sz1" style={{ display: 'inline' }}>
                    lub Serwisy zewnętrzne
                  </span>{' '}
                  podczas jednej sesji danego Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia Użytkownika.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies trwałe</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis{' '}
                  <span id="sz2" style={{ display: 'inline' }}>
                    lub Serwisy zewnętrzne
                  </span>{' '}
                  do momentu ich ręcznego usunięcia. Pliki nie są usuwane automatycznie po zakończeniu sesji Urządzenia chyba że
                  konfiguracja Urządzenia Użytkownika jest ustawiona na tryb usuwanie plików Cookie po zakończeniu sesji Urządzenia.
                </p>
              </li>
            </ul>

            <h6>§ 3 Bezpieczeństwo</h6>
            <ul>
              <li>
                <p>
                  <strong>Mechanizmy składowania i odczytu</strong> - Mechanizmy składowania i odczytu Cookies nie pozwalają na pobierania
                  jakichkolwiek danych osobowych ani żadnych informacji poufnych z Urządzenia Użytkownika. Przeniesienie na Urządzenie
                  Użytkownika wirusów, koni trojańskich oraz innych robaków jest praktynie niemożliwe.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookie wewnętrzne</strong> - zastosowane przez Administratora Cookie wewnętrzne są bezpieczne dla Urządzeń
                  Użytkowników
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookie zewnętrzne</strong> - za bezpieczeństwo plików Cookie pochodzących od partnerów Serwisu Administrator nie
                  ponosi odpowiedzialności. Lista partnerów zamieszczona jest w dalszej części Polityki Cookie.
                </p>
              </li>
            </ul>

            <h6>§ 4 Cele do których wykorzystywane są pliki Cookie</h6>
            <ul>
              <li>
                <p>
                  <strong>Dane statystyczne</strong> - Administrator oraz Serwisy zewnętrzne wykorzystuje pliki Cookie do zbirania i przetwarzania danych statystycznych takich jak np. statystyki odwiedzin, statystyki Urządzeń Użytkowników czy statystyki zachowań użytkowników. Dane te zbierane są w celu analizy i ulepszania Serwisu.
                </p>
              </li>
            </ul>
            <h6>§ 5 Serwisy zewnętrzne</h6>
            <p id="zewinfo">
              <span>
                Administrator współpracuje z następującymi serwisami zewnętrznymi, które mogą zamieszczać pliki Cookie na Urządzeniach
                Użytkownika:
              </span>
            </p>
            <ul id="zewnetrzne">
              <li>
                <p>
                  <strong>
                    <span>Google Analytics</span>
                  </strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    <span>Google maps</span>
                  </strong>
                </p>
              </li>
            </ul>
            <h6>
              § 6 Możliwości określania warunków przechowywania i uzyskiwania dostępu na Urządzeniach Użytkownika przez Serwis
              <span id="sz6" style={{ display: 'inline' }}>
                {' '}
                i Serwisy zewnętrzne
              </span>
            </h6>
            <ul>
              <li>
                <p>
                  Użytkownik może w dowolnym momencie, samodzielnie zmienić ustawienia dotyczące zapisywania, usuwania oraz dostępu do
                  danych zapisanych plików Cookies
                </p>
              </li>
              <li>
                <p>
                  Informacje o sposobie wyłączenia plików Cookie w najpopularniejszych przeglądarkach komputerowych i urządzeń mobilnych
                  dostępna są na stronie: <a href="http://jakwylaczyccookie.pl">jak wyłączyć cookie</a>.
                </p>
              </li>
              <li>
                <p>
                  Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do tej pory pliki Cookie korzystając z narzędzi Urządzenia
                  Użytkownika za pośrednictwem którego Użytkowanik korzysta z usług Serwisu.
                </p>
              </li>
            </ul>
            <h6>§ 7 Wymagania Serwisu</h6>
            <ul>
              <li>
                <p>
                  Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu Użytkownika może spowodować nieprawidłowe działanie
                  niektórych funkcji Serwisu.
                </p>
              </li>
              <li>
                <p>
                  Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo działające funkcje Serwisu w przypadku gdy Użytkownik
                  ograniczy w jakikolwiek sposób możliwość zapisywania i odczytu plików Cookie.
                </p>
              </li>
            </ul>
            <h6>§ 8 Zmiany w Polityce Cookie</h6>
            <ul>
              <li>
                <p>
                  Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej Polityki Cookie bez konieczności informowania o tym
                  użytkowników.
                </p>
              </li>
              <li>
                <p>Wprowadzone zmiany w Polityce Cookie zawsze będą publikowane na tej stronie.</p>
              </li>
              <li>
                <p>Wprowadzone zmiany wchodzą w życie w dniu publikacji Polityki Cookie.</p>
              </li>
            </ul>
          </Styles>
        </Flex>
      </BaseContainer>
    );
  }
}

export default CookiesPolicyPage;
