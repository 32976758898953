import React from 'react';
import { FormSpy } from 'react-final-form';
import { Button } from 'rebass';

const SubmitButton = ({ children, ...props }) => (
  <FormSpy subscription={{ submitting: true }}>
    {({ submitting }) => (
      <Button {...props} disabled={props.disabled || submitting}>
        {children}
      </Button>
    )}
  </FormSpy>
);

export default SubmitButton;
