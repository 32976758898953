import React from 'react';
import { Text, Box } from 'rebass';

const Error = ({ meta = {} }) => (
  <Box sx={{ position: 'relative' }}>
    {(meta.touched || meta.submitFailed) && (meta.error || meta.submitError) && (
      <Text color="red" fontSize={13} pt="5px" sx={{ position: 'absolute' }}>
        {meta.error || meta.submitError[0]}
      </Text>
    )}
  </Box>
);

export default Error;
