import React from 'react';
import { Text } from 'rebass';
import styled from '@emotion/styled';
// import BaseContainer from '../common/layouts/BaseContainer';

const Title = styled(Text)`
  color: #197941;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const Paragraph = styled(Text)`
  font-weight: 300;
  width: 90%;
  max-width: 800px;
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.5;

  a {
    text-decoration: none;
    font-weight: 500;
  }

  strong {
    font-weight: 500;
  }
`;

const Subtitle = styled(Text)`
  color: #197941;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
`;

const PageWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 145px 0;
  align-items: center;

  strong {
    font-weight: 700;
  }

  &:before, &:after {
    width: 100%;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
    background: url('/images/giftLanding/top.png') repeat-x 0 0 / 500px;
    z-index: -1;
  }

  &:after {
    top: auto;
    bottom: 0;
    background: url('/images/giftLanding/bottom.png') repeat-x 0 100% / 500px;
  }

  @media (max-width: 480px) {
    padding: 145px 15px;
  }
`;

const Logo = styled.img`
  max-width: 400px;
  display: block;
  margin-bottom: 50px;
  width: 74%;
`;

const Button = styled.a`
  display: block;
  padding: 15px 80px;
  background: #197941;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.5);
  border-radius: 8px;
  margin: 30px 0 50px;
`;

const Steps = styled.ul`
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 60px 0;
  flex-wrap: wrap;

  @media (max-width: 764px) {
    flex-direction: column;
  }
`;

const Step = styled.li`
  text-align: center;
  font-size: 13px;
  flex: 1;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    background: url('/images/giftLanding/right-arrow.svg') no-repeat 50% 50% / contain;
    right: -12px;
    top: 30px;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  > img {
    display: block;
    margin: 0 auto 15px;
    height: 50px;
    width: 100%;
    object-fit: contain;
  }

  > h3 {
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
  }

  > span {
    font-weight: 300;
    padding: 0 15px;
    line-height: 1.5;
    display: block;
    padding: 0 30px;
    @media (max-width: 900px) {
      padding: 0 15px;
    }

    strong, a {
      font-weight: 500;
      text-decoration: none;
    }
  }

  @media (max-width: 764px) {
    width: 100%;
    flex: unset;
    margin-bottom: 60px;

    > img {
      height: 100px;
    }

    > span {
      max-width: 300px;
      margin: 0 auto;
    }

    &:after {
      top: calc(100% + 16px);
      right: calc(50% - 12px);
      transform: rotate(90deg);
    }
  }
`;

const Small = styled(Text)`
  font-size: 13px;
  display: flex;
  align-items: center;

  > img {
    display: block;
    margin-right: 10px;
    width: 36px;
    height: 36px;
    margin-top: 2px;
  }
`;

const Partners = styled.div`
  display: flex;
  margin: 30px 0 25px;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;

  > img {
    display: block;
    margin: 0 10px;
    height: 90px;
    max-width: 150px;
    object-fit: contain;
  }

  @media (max-width: 764px) {
    > img {
      margin: 15px;
    }
  }
`;

const STEPS = [
  {
    no: 1,
    image: '/images/giftLanding/sygnet.png',
    text: "Wejdź na stronę <a href='https://www.aimedo.gift' target='_blank' rel='noopener noreferrer'>www.aimedo.gift</a>",
  },
  {
    no: 2,
    image: '/images/giftLanding/comp.png',
    text: 'Zaloguj się przy użyciu <strong>numeru aimedo.gift (bonu podarunkowego) i kodu CVV</strong>, które znajdziesz w PDF załączonym do wiadomości z życzeniami',
  },
  {
    no: 3,
    image: '/images/giftLanding/web.png',
    text: 'Wybierz interesujące punkty akceptujące, wartość bonów i kliknij <strong>"Pobierz"</strong>',
  },
  {
    no: 4,
    image: '/images/giftLanding/pdf.png',
    text: 'Pobierz bony <strong>w formacie .pdf lub podaj adres e-mail</strong>, na który wyślemy wybrane przez Ciebie bony.',
  },
  {
    no: 5,
    image: '/images/giftLanding/cart.png',
    text: 'Wykorzystaj bony towarowe na <strong>stronach internetowych partnerów lub w punktach stacjonarnych</strong>.',
  },
];

const PARTNERS = [
  '/images/giftLanding/logos/ob/pharmena.png',
  '/images/giftLanding/logos/ob/ale.png',
  '/images/giftLanding/logos/ob/menavitin.png',
  '/images/giftLanding/logos/ob/natura.png',
  '/images/giftLanding/logos/ob/doz.png',
  '/images/giftLanding/logos/ob/max.png',
  '/images/giftLanding/logos/ob/medme.png',
  '/images/giftLanding/logos/ob/szpital.png',
  '/images/giftLanding/logos/ob/phamily.png',
];

const GitfLandingPage = () => (
  <PageWrapper>
    <Logo src="/images/giftLanding/logo.jpg" alt="pelion" />
    <Title>Szanowni Państwo!</Title>
    <Paragraph>
      Przekazujemy w Państwa ręce
      {' '}
      <strong>aimedo.gift</strong>
      , wyjątkowy bon podarunkowy,
      który można wykorzystać na zakup produktów i usług dostępnych w ramach bogatej oferty
      sklepów internetowych oraz punktów detalicznych spółek Grupy Pelion.
    </Paragraph>

    <Paragraph>
      Środki z
      {' '}
      <strong>aimedo.gift</strong>
      {' '}
      można wymienić na bony towarowe uprawniające do zakupów w punktach naszych Partnerów.
      Pełna lista punktów akceptujących oraz oferta bonów towarowych dostępna jest na stronie
      {' '}
      <a href="https://www.aimedo.gift" target="_blank" rel="noopener noreferrer">www.aimedo.gift</a>
      .
    </Paragraph>

    <Subtitle mt={30}>Wejdź na aimedo.gift i skorzystaj z bonu</Subtitle>

    <Button href="https://www.aimedo.gift" target="_blank" rel="noopener noreferrer">aimedo.gift</Button>

    <Subtitle>Jak skorzystać z aimedo.gift?</Subtitle>

    <Steps>
      {STEPS.map(({ no, image, text }) => (
        <Step key={no}>
          <img src={image} alt={`krok ${no}`} />
          <h3>
            Krok
            {no}
          </h3>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Step>
      ))}
    </Steps>

    <Small>Partnerzy oferujący zakup produktów i usług w ramach aimedo.gift</Small>
    <Partners>
      {PARTNERS.map((logo, index) => <img src={logo} key={`partner-${index}`} alt="" />)}
    </Partners>
    <Small>
      <img src="/images/giftLanding/pharm.png" alt="" />
      ponad 7 500 aptek stacjonarnych w całej Polsce
    </Small>
  </PageWrapper>
);

export default GitfLandingPage;
