import React, { Component } from 'react';
import {
  Flex, Button,
} from 'rebass';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import { inject, observer } from 'mobx-react';

@inject('mapStore')
@observer
class PlacesAutocomplete extends Component {
  state = {
    coordinates: null,
  };

  handleChange = address => this.setState({ address });

  handleSelect = async (address) => {
    try {
      const geocode = await geocodeByPlaceId(address.place_id);
      const coordinates = await getLatLng(geocode[0]);
      this.setState({ coordinates });
    } catch (e) {
      this.setState({ coordinates: null });
      console.log(e);
    }
  };

  onSubmit = () => {
    const { fetchLocations } = this.props.mapStore;
    const { coordinates } = this.state;
    this.props.onSubmit(coordinates);
    fetchLocations(coordinates);
  };

  render() {
    const { coordinates } = this.state;
    return (
      <Flex mb={37} flexWrap={['wrap', 'nowrap']}>
        <GooglePlacesAutocomplete
          placeholder="Wpisz lokalizację"
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          debounce={600}
          loader={<Flex />}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['pl'],
            },
          }}
          inputStyle={{
            width: '100%',
            height: 50,
            marginBottom: 0,
            boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)',
          }}
          suggestionsStyles={{
            suggestion: {
              padding: '20px 10px',
              cursor: 'pointer',
            },
          }}
        />
        <Button
          width={[1, 250]}
          ml={[0, 37]}
          mt={[20, 0]}
          disabled={!coordinates}
          onClick={this.onSubmit}
        >
          POKAŻ PUNKTY
        </Button>
      </Flex>
    );
  }
}

export default PlacesAutocomplete;
