import styled from '@emotion/styled';
import React from 'react';
import toNumber from 'lodash/toNumber';
import { Box, Flex } from 'rebass';
import { Label } from '@rebass/forms';
import Error from './Error';

const Operator = styled(Flex)`
  align-items: center;
  cursor: pointer;
  padding: 8px;
`;

const Wrapper = styled(Flex)`
  border-radius: 4px;
  border: 1px solid;
  height: 34px;
  font-size: 16px;
  min-width: 100px;
  justify-content: space-between;
  align-items: center;
`;

const Stepper = ({
  label,
  input: { value, onChange },
  meta,
  max,
  afterChange,
}) => (
  <Box width={1}>
    {label && (
      <Label mb="5px" fontSize={15}>
        {label}
      </Label>
    )}
    <Wrapper>
      <Operator
        sx={{ borderRight: '1px solid' }}
        onClick={() => {
          const v = value <= 0 ? value : toNumber(value) - 1;
          onChange(v);
          if (typeof afterChange === 'function') {
            afterChange(v);
          }
        }}
      >
        -
      </Operator>
      {value || 0}
      <Operator
        sx={{ borderLeft: '1px solid' }}
        onClick={() => {
          if (max && toNumber(value) < max) {
            const v = toNumber(value) + 1;
            onChange(v);
            if (typeof afterChange === 'function') {
              afterChange(v);
            }
          }
        }}
      >
        +
      </Operator>
    </Wrapper>
    <Error meta={meta} />
  </Box>
);

export default Stepper;
