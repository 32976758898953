const newStatue = [
  {
    title: '§ 1 Terminologia',
    subtitle: 'Użyte w niniejszym Regulaminie określenia oznaczają:',
    list: [
      {
        description:
          'Wydawca – epruf s.a. z siedzibą w Łodzi, ul. Zbąszyńska 3, 91-342 Łódź, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi, XX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000319410, NIP: 9471958279, kapitał zakładowy 700 000 złotych w całości wpłacony.',
      },
      {
        description:
          'Bon towarowy aimedo.gift / aimedo.gift – bon towarowy na okaziciela dostępny w formie karty plastikowej, karty papierowej bądź z poziomu nośnika elektronicznego uprawniający do wymiany jego wartości na Bony towarowe Akceptanta lub Akceptantów o łącznej wartości nie wyższej niż wartość Bonu towarowego aimedo.gift. Bon towarowy aimedo.gift posiada określoną Wartość do wykorzystania oraz datę ważności. Bon towarowy aimedo.gift nie jest kartą płatniczą, ani instrumentem pieniądza elektronicznego w rozumieniu obowiązujących przepisów prawa.',
      },
      {
        description:
          'Akceptant – podmiot prowadzący Punkt akceptujący lub Punkty akceptujące, w których istnieje możliwość realizacji Bonów towarowych Akceptanta wygenerowanych w Serwisie aimedo.gift.',
      },
      {
        description:
          'Bon towarowy Akceptanta – bon towarowy bądź kod rabatowy otrzymywany przez Klienta w drodze wymiany środków posiadanych w ramach aimedo.gift. Bon towarowy Akceptanta uprawnia do zakupu Towarów oferowanych przez Akceptanta/ów oraz posiada zdefiniowaną w Serwisie aimedo.gift Wartość do wykorzystania oraz datę ważności.',
      },
      { description: 'Nabywca – podmiot, który nabył od Wydawcy Bony towarowe aimedo.gift o określonej Wartości do wykorzystania.' },
      { description: 'Klient – posiadacz aimedo.gift, który w ramach Serwisu aimedo.gift korzysta z udostępnionych funkcjonalności.' },
      {
        description:
          'Wartość do wykorzystania – zapisana w formie informacji elektronicznej przez Wydawcę wartość pieniężna aimedo.gift, którą Klient może wymienić na Bony towarowe Akceptanta w Serwisie aimedo.gift w okresie ważności aimedo.gift.',
      },
      {
        description:
          'Serwis aimedo.gift – serwis internetowy prowadzony przez Wydawcę pod adresem www.aimedo.gift umożliwiający wymianę aimedo.gift na Bon/y towarowy/e Akceptanta o określonej kwocie, a także sprawdzenie Wartości do wykorzystania i terminu ważności aimedo.gift.',
      },
      {
        description:
          'Punkt akceptujący – prowadzone przez Akceptanta placówki handlowo-usługowe, placówki ochrony zdrowia lub zakłady lecznicze działające stacjonarnie bądź za pośrednictwem Internetu, w tym internetowe systemy składania zamówień oraz placówki realizujące zamówienia składane za pośrednictwem Internetu, (e-commerce), w których Klient może zakupić Towary z wykorzystaniem Bonu towarowego Akceptanta.',
      },
      {
        description:
          'Transakcja – operacja polegająca na wymianie aimedo.gift na Bon/y towarowy/e Akceptanta poprzez obniżenie Wartości do wykorzystania Bonu towarowego aimedo.gift o kwotę odpowiadającą nominałowi Bonu/ów towarowego/ych Akceptanta. ',
      },
      {
        description:
          'Towary – produkty i usługi oferowane przez Akceptantów w Punktach akceptujących, które Klient może nabyć z wykorzystaniem Bonu towarowego Akceptanta. ',
      },
    ],
  },
  {
    title: '§ 2 Postanowienia ogólne',
    list: [
      {
        description:
          'aimedo.gift służy do wymiany na Bon/y towarowy/e Akceptanta/ów, które umożliwiają Klientowi dokonanie zakupu Towarów w Punkcie/tach akceptującym/ch. Wykaz Punktów akceptujących znajduje się na stronie internetowej Serwisu aimedo.gift pod adresem www.aimedo.gift. ',
      },
      {
        description:
          'Poprzez przystąpienie do realizacji Transakcji Klient zawiera z Wydawcą umowę na wykorzystanie Bonu towarowego aimedo.gift zgodnie z treścią niniejszego Regulaminu. ',
      },
      { description: 'aimedo.gift nie podlega zwrotowi ani wymianie na gotówkę.' },
      {
        description:
          'aimedo.gift ma określoną datę ważności i może być wymieniony na Bony towarowe Akceptantów w okresie ustalonym przez Wydawcę. Klient może sprawdzić termin ważności Bonu towarowego aimedo.gift na stronie internetowej www.aimedo.gift.',
      },
      {
        description:
          'Nabywca może otrzymać Bony towarowe aimedo.gift po zawarciu umowy z Wydawcą i wykonaniu zawartych w niej zobowiązań. Nabywca ma prawo przekazać Bony towarowe aimedo.gift dowolnie wybranym przez siebie podmiotom. ',
      },
      {
        description:
          'Początkowa Wartość do wykorzystania Bonu towarowego aimedo.gift ustalana jest przez Nabywcę w porozumieniu z Wydawcą. Wartość do wykorzystania stanowi wielokrotność kwoty 10 zł z zastrzeżeniem, że najniższy nominał Bonu towarowego aimedo.gift to 50 zł.',
      },
      {
        description:
          'Wydawca nie ponosi odpowiedzialności za Bony towarowe aimedo.gift, które zostały utracone lub uszkodzone po ich wydaniu Nabywcy. ',
      },
    ],
  },

  {
    title: '§ 3 Zasady wymiany Bonów towarowych aimedo.gift oraz realizacja zakupów z ich użyciem',
    list: [
      {
        description:
          'Klient może wymieniać środki z Bonu towarowego aimedo.gift na stronie www.aimedo.gift na Bon/y towarowy/e Akceptanta/ów wielokrotnie do wyczerpania Wartości do wykorzystania, w okresie jego ważności. Informacja o terminie ważności aimedo.gift znajduje się na stronie www.aimedo.gift.',
      },

      {
        description:
          'Po dokonaniu Transakcji można dokonać pobrania Bonu towarowego Akceptanta w formie pliku .pdf bądź podać adres e-mail, na który Wydawca prześle bon towarowy Akceptanta w formie wiadomości e-mail.',
      },

      {
        description:
          'Bon towarowy Akceptanta można wykorzystać w Punktach akceptujących w celu zakupu Towarów oferowanych przez Akceptanta. Zakup Towarów z wykorzystaniem Bonu towarowego Akceptanta podlega standardowej fiskalizacji w Punkcie akceptującym. ',
      },

      {
        description:
          'Zasady wykorzystania Bonu towarowego Akceptanta w celu zakupu Towarów wskazane są w treści Bonu towarowego Akceptanta. ',
      },

      {
        description:
          'Klient aimedo.gift może sprawdzić aktualną Wartość do wykorzystania na stronie internetowej www.aimedo.gift po zalogowaniu się przy pomocy znajdujących się na odwrocie Bonu towarowego aimedo.gift numeru karty aimedo.gift oraz kodu CVV.',
      },

      {
        description:
          'Wymiana Bonu towarowego aimedo.gift przez Klienta stanowi ważną Transakcję nawet wówczas, gdy wejdzie on w jego posiadanie w sposób nieuprawniony. Bon towarowy Akceptanta może być wykorzystany do zakupu Towarów w Punkcie akceptującym tylko w terminie jego ważności. ',
      },
      {
        description:
          'W przypadku realizacji Bonu towarowego Akceptanta podczas składania zamówienia na stronie Punktu akceptującego należy we wskazanym polu wpisać kod bonu, który znajduje się na Bonie towarowym Akceptanta pobranym ze strony www.aimedo.gift.',
      },
      {
        description:
          'W przypadku gdy wartość nabywanych Towarów przekracza wartość przypisaną do Bonu towarowego Akceptanta, zakup Towarów będzie mógł być dokonany pod warunkiem zapłaty różnicy przez Klienta dowolnym środkiem płatniczym akceptowanym przez Akceptanta. ',
      },
      {
        description:
          'Po wymianie aimedo.gift na Bon towarowy Akceptanta nie ma możliwości zwrotu oraz wymiany na inny Bon towarowy Akceptanta. Szczegółowe informacje dotyczące realizacji Bonu towarowego Akceptanta zamieszczone są na stronie www.aimedo.gift w zakładce „Szczegóły bonu” oraz dostępne przed wymianą aimedo.gift.',
      },
      {
        description:
          'W przypadku wystąpienia problemów technicznych w realizacji Transakcji przy użyciu aimedo.gift, Klient może zgłosić zaistniały problem za pośrednictwem infolinii Wydawcy, nr tel. 42 612 09 91 (czynnej w dni robocze w godzinach 8.00-16.00) lub w formie elektronicznej poprzez adres: pomoc@aimedo.gift.',
      },

      {
        description: 'Reklamacje związane:',
        children: [
          {
            description:
              'z Bonem towarowym aimedo.gift powinny być składane do Wydawcy niezwłocznie po ujawnieniu nieprawidłowości pisemnie na adres siedziby Wydawcy, telefonicznie pod numerem infolinii Wydawcy 42 612 09 91 (czynnej w dni robocze w godzinach 8.00-16.00) lub w formie elektronicznej na adres: pomoc@aimedo.gift i będą rozpatrywane przez Wydawcę, w terminie 14 dni od dnia złożenia reklamacji przez Klienta. Reklamacje złożone pisemnie będą uznane za skutecznie doręczone w dniu otrzymania pisma przez Wydawcę. ',
          },
          {
            description:
              'z realizacją Bonu towarowego przez Akceptanta powinny być składane bezpośrednio do Akceptanta według ustalonych przez niego zasad. ',
          },
        ],
      },
    ],
  },
  {
    title: '§ 4 Postanowienia końcowe',
    list: [
      { description: 'W sprawach nieuregulowanych postanowieniami Regulaminu mają zastosowanie odpowiednie przepisy kodeksu cywilnego.' },
      {
        description:
          'Klient logując się do Serwisu aimedo.gift, składa oświadczenie, że zapoznał się z niniejszym Regulaminem, nie wnosi zastrzeżeń co do jego treści i zobowiązuje się go przestrzegać.',
      },
      {
        description:
          'Treść niniejszego Regulaminu jest dostępna na stronie internetowej pod adresem www.aimedo.gift. Wydawca przekaże Nabywcy lub Klientowi treść Regulaminu w formie pisemnej na każde jego pisemne żądanie.',
      },
      {
        description:
          'Wydawca zastrzega sobie prawo do dokonywania zmian w Regulaminie bez podania przyczyny. O zmianach treści Regulaminu Wydawca poinformuje z co najmniej 14-dniowym wyprzedzeniem na stronie internetowej www.aimedo.gift. Do Transakcji dokonanych przed dniem wejścia w życie nowego brzmienia Regulaminu, stosuje się postanowienia Regulaminu w brzmieniu obowiązującym w dniu dokonania Transakcji.',
      },
    ],
  },
  {
    title: '§ 5 Dane osobowe',
    list: [
      {
        description:
          'Administratorem danych osobowych zbieranych za pośrednictwem Serwisu aimedo.gift jest epruf s.a. z siedzibą w Łodzi, ul. Zbąszyńska 3, 91-342 Łódź, dane kontaktowe: ul. Zbąszyńska 3, 91-342 Łódź, tel. 48 200 75 68 (zwana dalej „Administratorem”) będąca właścicielem platformy internetowej www.aimedo.gift.',
      },

      {
        description:
          'Administrator w celu należytej ochrony danych osobowych powołał Inspektora Ochrony Danych. Kontakt do IOD - e-mail: iod@epruf.pl',
      },

      {
        description: 'Administrator przetwarza podane w formularzach dane osobowe Klienta w celach:',
        children: [
          {
            description:
              'realizacji usług wymiany Bonów towarowych aimedo.gift na Bony towarowe Akceptantów – podstawą prawną przetwarzania danych jest wykonanie umowy (art. 6 ust. 1 lit. b RODO),',
          },
          {
            description:
              'obsługa infolinii, rozpatrzenia zgłoszeń w tym rozpatrzenia reklamacji, dochodzenia i obrony w razie zaistnienia wzajemnych roszczeń – podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Wydawcy bonów (art. 6 ust. 1 lit. f RODO),',
          },
          {
            description:
              'oceny jakości świadczonych usług, pozyskania opinii Klienta o działaniach realizowanych przez Wydawcę i Akceptantów odnośnie przeprowadzonych Transakcji. Podstawą prawną przetwarzania danych osobowych jest prawnie uzasadniony interes administratora (art. 6 ust. 1 lit. f RODO),',
          },
          {
            description:
              'prowadzenia i bieżącego zarządzania kontami aimedo.gift w mediach społecznościowych, w tym w celu komunikowania się ze społecznością oraz organizowania wydarzeń lub promocji na zasadach określonych funkcjonalnościami poszczególnych mediów społecznościowych oraz zgodnie z ich regulaminami. Podstawą prawną przetwarzania danych jest zgoda, np. w formie kliknięcia na wtyczkę „Lubię to”, (art. 6 ust. 1 lit a RODO).',
          },
        ],
      },

      {
        description:
          'Podanie danych osobowych Klienta jest dobrowolne, lecz niezbędne w celu wykonania usług w odpowiedni sposób. Klient ma możliwość niepodawania swoich danych osobowych i w takim przypadku usługi są świadczone z wyłączeniem użycia poczty elektronicznej.',
      },

      {
        description:
          'Dane osobowe Klientów będą przetwarzane przez Wydawcę przez okres ważności Bonu towarowego aimedo.gift, a po jego zakończeniu do czasu upływu okresu przedawnienia roszczeń z tytułu korzystania z Bonu towarowego aimedo.gift, bądź w razie powstania sporu do czasu wygaśnięcia roszczeń stron. Dane osobowe przetwarzane w celach pozyskiwania opinii przechowywane będą do czasu wniesienia sprzeciwu.',
      },

      {
        description:
          'Dane osobowe Klientów mogą być ujawniane przez Administratora podmiotom z nim współpracującym (odbiorcom danych), tj.:',
        children: [
          {
            description:
              'podmiotom dostarczającym i wspierającym systemy informatyczne stosowane przez Administratora w celu obsługi Serwisu aimedo.gift.',
          },
          {
            description:
              'podmiotom świadczącym usługi związane z działalnością Wydawcy w celu realizacji przez niego obowiązków wynikających z Transakcji – na podstawie umowy zawartej między Wydawcą a Klientem w szczególności w zakresie: obsługi Transakcji, obsługi Klienta (telefonicznej i e-mailowej), a także podmiotowi świadczącemu usługę lub dostarczającemu narzędzia do kontaktu telefonicznego i elektronicznego z Klientem.',
          },
          { description: 'podmiotom świadczącym usługi w zakresie zbierania opinii' },
          {
            description:
              'dostawcom mediów społecznościowych, w szczególności Facebook Ireland Ltd, na mocy regulaminów tych mediów społecznościowych oraz przy zapewnieniu stosowania przez ww. podmioty adekwatnych środków technicznych i organizacyjnych zapewniających ochronę danych, a także podmiotom świadczącym usługi zarządzania i konsultingu w zakresie kont Serwisu aimedo.gift w mediach społecznościowych, na mocy stosownych umów powierzenia przetwarzania danych osobowych oraz przy zapewnieniu stosowania przez ww. podmioty adekwatnych środków technicznych i organizacyjnych zapewniających ochronę danych.',
          },
        ],
      },
      {
        description:
          'Każdej osobie, której dane są przetwarzane, w zakresie wynikającym z przepisów prawa, przysługuje prawo dostępu do swoich danych oraz ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu wobec przetwarzania danych, które można wykonać poprzez wysłanie wiadomość e-mail na adres pomoc@aimedo.gift lub telefonicznie pod numerem tel.: 42 612 09 91.',
      },
      {
        description:
          'W przypadku wątpliwości związanych z przetwarzaniem danych osobowych każda osoba może zwrócić się do Administratora z prośbą o udzielenie informacji. Niezależnie od powyższego, każdemu przysługuje prawo wniesienia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych.',
      },
    ],
  },
];

const oldStatue = [
  {
    title: '§ 1 Terminologia',
    subtitle: 'Użyte w niniejszym Regulaminie określenia oznaczają:',
    list: [
      {
        description:
          'Wydawca – epruf s.a. z siedzibą w Łodzi, ul. Zbąszyńska 3, 91-342 Łódź, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla Łodzi-Śródmieścia w Łodzi, XX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000319410, NIP: 9471958279, kapitał zakładowy 700 000 złotych w całości wpłacony.',
      },
      {
        description:
          'Bon towarowy aimedo.gift / aimedo.gift – bon towarowy na okaziciela dostępny w formie karty plastikowej, karty papierowej bądź z poziomu nośnika elektronicznego uprawniający do wymiany jego wartości na Bony towarowe Akceptanta lub Akceptantów o łącznej wartości nie wyższej niż wartość Bonu towarowego aimedo.gift. Bon towarowy aimedo.gift posiada określoną Wartość do wykorzystania oraz datę ważności. Bon towarowy aimedo.gift nie jest kartą płatniczą, ani instrumentem pieniądza elektronicznego w rozumieniu obowiązujących przepisów prawa.',
      },
      {
        description:
          'Akceptant – podmiot prowadzący Punkt akceptujący lub Punkty akceptujące, w których istnieje możliwość realizacji Bonów towarowych Akceptanta wygenerowanych w Serwisie aimedo.gift.',
      },
      {
        description:
          'Bon towarowy Akceptanta – bon towarowy bądź kod rabatowy otrzymywany przez Klienta w drodze wymiany środków posiadanych w ramach aimedo.gift. Bon towarowy Akceptanta uprawnia do zakupu Towarów oferowanych przez Akceptanta/ów oraz posiada zdefiniowane w Serwisie aimedo.gift Wartość do wykorzystania oraz datę ważności.',
      },
      { description: 'Nabywca – podmiot, który nabył od Wydawcy Bony towarowe aimedo.gift o określonej Wartości do wykorzystania.' },
      { description: 'Klient – posiadacz aimedo.gift, który w ramach Serwisu aimedo.gift korzysta z udostępnionych funkcjonalności.' },
      {
        description:
          'Wartość do wykorzystania – zapisana w formie informacji elektronicznej przez Wydawcę wartość pieniężna aimedo.gift, którą Klient może wymienić na Bony towarowe Akceptanta w Serwisie aimedo.gift w okresie ważności aimedo.gift.',
      },
      {
        description:
          'Serwis aimedo.gift – serwis internetowy prowadzony przez Wydawcę pod adresem www.aimedo.gift umożliwiający wymianę aimedo.gift na Bon/y towarowy/e Akceptanta o określonej kwocie, a także sprawdzenie Wartości do wykorzystania i terminu ważności aimedo.gift.',
      },
      {
        description:
          'Punkt akceptujący – prowadzone przez Akceptanta placówki handlowo-usługowe, placówki ochrony zdrowia lub zakłady lecznicze działające stacjonarnie bądź za pośrednictwem Internetu, w tym internetowe systemy składania zamówień oraz placówki realizujące zamówienia składane za pośrednictwem Internetu, (e-commerce), w których Klient może zakupić Towary z wykorzystaniem Bonu towarowego Akceptanta.',
      },
      {
        description:
          'Transakcja – operacja polegająca na wymianie aimedo.gift na Bon/y towarowy/e Akceptanta poprzez obniżenie Wartości do wykorzystania Bonu towarowego aimedo.gift o kwotę odpowiadającą nominałowi Bonu/ów towarowego/ych Akceptanta. ',
      },
      {
        description:
          'Towary – produkty i usługi oferowane przez Akceptantów w Punktach akceptujących, które Klient może nabyć z wykorzystaniem Bonu towarowego Akceptanta. ',
      },
    ],
  },
  {
    title: '§ 2 Postanowienia ogólne',
    list: [
      {
        description:
          'aimedo.gift służy do wymiany na Bon/y towarowy/e Akceptanta/ów, które umożliwiają Klientowi dokonanie zakupu Towarów w Punkcie/tach akceptującym/ch. Wykaz Punktów akceptujących znajduje się na stronie internetowej Serwisu aimedo.gift pod adresem www.aimedo.gift. ',
      },
      {
        description:
          'Poprzez przystąpienie do realizacji Transakcji Klient zawiera z Wydawcą umowę na wykorzystanie Bonu towarowego aimedo.gift zgodnie z treścią niniejszego Regulaminu. ',
      },
      { description: 'aimedo.gift nie podlega zwrotowi ani wymianie na gotówkę.' },
      {
        description:
          'aimedo.gift ma określoną datę ważności i może być wymieniony na Bony towarowe Akceptantów w okresie ustalonym przez Wydawcę. Klient może sprawdzić termin ważności Bonu towarowego aimedo.gift na stronie internetowej www.aimedo.gift.',
      },
      {
        description:
          'Nabywca może otrzymać Bony towarowe aimedo.gift po zawarciu umowy z Wydawcą i wykonaniu zawartych w niej zobowiązań. Nabywca ma prawo przekazać Bony towarowe aimedo.gift dowolnie wybranym przez siebie podmiotom. ',
      },
      {
        description:
          'Pierwotna Wartość do wykorzystania Bonu towarowego aimedo.gift ustalana jest przez Nabywcę w porozumieniu z Wydawcą. Wartość do wykorzystania stanowi wielokrotność kwoty 10 zł z zastrzeżeniem, że najniższy nominał Bonu towarowego aimedo.gift to 50 zł.',
      },
      { description: 'Wydawca ponosi odpowiedzialność za aimedo.gift do momentu jego wymiany na Bon towarowy Akceptanta. ' },
      {
        description:
          'Wydawca nie ponosi odpowiedzialności za Bony towarowe aimedo.gift, które zostały utracone lub uszkodzone po ich wydaniu Nabywcy. ',
      },
      {
        description:
          'Wydawca nie ponosi odpowiedzialności za skutki braku możliwości realizacji Bonów towarowych Akceptantów w Punktach akceptujących leżące po stronie Akceptantów lub wynikające z działania siły wyższej. ',
      },
    ],
  },

  {
    title: '§ 3 Zasady wymiany Bonów towarowych aimedo.gift oraz realizacja zakupów z ich użyciem',
    list: [
      {
        description:
          'Klient może wymieniać środki z Bonu towarowego aimedo.gift na stronie www.aimedo.gift na Bon/y towarowy/e Akceptanta/ów wielokrotnie do wyczerpania Wartości do wykorzystania, w okresie jego ważności. Informacja o terminie ważności aimedo.gift znajduje się na stronie www.aimedo.gift.',
      },

      {
        description:
          'Po dokonaniu Transakcji można dokonać pobrania Bonu towarowego Akceptanta w formie pliku .pdf bądź podać adres e-mail, na który Wydawca prześle bon towarowy Akceptanta w formie wiadomości e-mail.',
      },

      {
        description:
          'Bon towarowy Akceptanta można wykorzystać w Punktach akceptujących w celu zakupu Towarów oferowanych przez Akceptanta. Zakup Towarów z wykorzystaniem Bonu towarowego Akceptanta podlega standardowej fiskalizacji w Punkcie akceptującym. ',
      },

      {
        description:
          'Zasady wykorzystania Bonu towarowego Akceptanta w celu zakupu Towarów wskazane są w treści Bonu towarowego Akceptanta. ',
      },

      {
        description:
          'Klient aimedo.gift może sprawdzić aktualną Wartość do wykorzystania na stronie internetowej www.aimedo.gift po zalogowaniu się przy pomocy znajdujących się na odwrocie Bonu towarowego aimedo.gift numeru karty aimedo.gift oraz kodu CVV.',
      },

      {
        description:
          'Wymiana Bonu towarowego aimedo.gift przez Klienta stanowi ważną Transakcję nawet wówczas, gdy wejdzie on w jego posiadanie w sposób nieuprawniony. Bon towarowy Akceptanta może być wykorzystany do zakupu Towarów w Punkcie akceptującym tylko w terminie jego ważności. ',
      },
      {
        description:
          'W przypadku realizacji Bonu towarowego Akceptanta podczas składania zamówienia na stronie Punktu akceptującego należy we wskazanym polu wpisać kod bonu, który znajduje się na Bonie towarowym Akceptanta pobranym ze strony www.aimedo.gift.',
      },
      {
        description:
          'W przypadku gdy wartość nabywanych Towarów przekracza wartość przypisaną do Bonu towarowego Akceptanta, zakup Towarów będzie mógł być dokonany pod warunkiem zapłaty różnicy przez Klienta dowolnym środkiem płatniczym akceptowanym przez Akceptanta. ',
      },
      {
        description:
          'Po wymianie aimedo.gift na Bon towarowy Akceptanta nie ma możliwości zwrotu oraz wymiany na inny Bon towarowy Akceptanta. Szczegółowe informacje dotyczące realizacji Bonu towarowego Akceptanta zamieszczone są na stronie www.aimedo.gift w zakładce „Szczegóły bonu” oraz dostępne przed wymianą aimedo.gift.',
      },
      {
        description:
          'W przypadku wystąpienia problemów technicznych w realizacji Transakcji przy użyciu aimedo.gift, Klient może zgłosić zaistniały problem za pośrednictwem infolinii Wydawcy, nr tel. 42 612 09 91 (czynnej w dni robocze w godzinach 8.00-16.00) lub w formie elektronicznej poprzez adres: pomoc@aimedo.gift.',
      },

      {
        description: 'Reklamacje związane:',
        children: [
          {
            description:
              'z Bonem towarowym aimedo.gift powinny być składane do Wydawcy niezwłocznie po ujawnieniu nieprawidłowości pisemnie na adres siedziby Wydawcy, telefonicznie pod numerem infolinii Wydawcy 42 612 09 91 (czynnej w dni robocze w godzinach 8.00-16.00) lub w formie elektronicznej na adres: pomoc@aimedo.gift i będą rozpatrywane przez Wydawcę, w terminie 14 dni od dnia złożenia reklamacji przez Klienta. Reklamacje złożone pisemnie będą uznane za skutecznie doręczone w dniu otrzymania pisma przez Wydawcę. ',
          },
          {
            description: 'z realizacją Bonu towarowego Akceptanta powinny być składane do Akceptanta według ustalonych przez niego zasad. ',
          },
        ],
      },
    ],
  },
  {
    title: '§ 4 Postanowienia końcowe',
    list: [
      { description: 'W sprawach nieuregulowanych postanowieniami Regulaminu mają zastosowanie odpowiednie przepisy kodeksu cywilnego.' },
      {
        description:
          'Klient logując się do Serwisu aimedo.gift, składa oświadczenie, że zapoznał się z niniejszym Regulaminem, nie wnosi zastrzeżeń co do jego treści i zobowiązuje się go przestrzegać.',
      },
      {
        description:
          'Treść niniejszego Regulaminu jest dostępna na stronie internetowej pod adresem www.aimedo.gift. Wydawca przekaże Nabywcy lub Klientowi treść Regulaminu w formie pisemnej na każde jego pisemne żądanie.',
      },
      {
        description:
          'Wydawca zastrzega sobie prawo do dokonywania zmian w Regulaminie bez podania przyczyny. O zmianach treści Regulaminu Wydawca poinformuje z co najmniej 14-dniowym wyprzedzeniem na stronie internetowej www.aimedo.gift. Do Transakcji dokonanych przed dniem wejścia w życie nowego brzmienia Regulaminu, stosuje się postanowienia Regulaminu w brzmieniu obowiązującym w dniu dokonania Transakcji.',
      },
    ],
  },
  {
    title: '§ 5 Dane osobowe',
    list: [
      {
        description:
          'Administratorem danych osobowych zbieranych za pośrednictwem Serwisu aimedo.gift jest epruf s.a. z siedzibą w Łodzi, ul. Zbąszyńska 3, 91-342 Łódź, dane kontaktowe: ul. Zbąszyńska 3, 91-342 Łódź, tel. 48 200 75 68 (zwana dalej „Administratorem”) będąca właścicielem platformy internetowej www.aimedo.gift.',
      },

      {
        description:
          'Administrator w celu należytej ochrony danych osobowych powołał Inspektora Ochrony Danych. Kontakt do IOD - e-mail: iod@epruf.pl',
      },

      {
        description: 'Administrator przetwarza podane w formularzach dane osobowe Klienta w celach:',
        children: [
          {
            description:
              'realizacji usług wymiany Bonów towarowych aimedo.gift na Bony towarowe Akceptantów – podstawą prawną przetwarzania danych jest wykonanie umowy (art. 6 ust. 1 lit. b RODO),',
          },
          {
            description:
              'obsługa infolinii, rozpatrzenia zgłoszeń w tym rozpatrzenia reklamacji, dochodzenia i obrony w razie zaistnienia wzajemnych roszczeń – podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Wydawcy bonów (art. 6 ust. 1 lit. f RODO),',
          },
          {
            description:
              'oceny jakości świadczonych usług, pozyskania opinii Klienta o działaniach realizowanych przez Wydawcę i Akceptantów odnośnie przeprowadzonych Transakcji. Podstawą prawną przetwarzania danych osobowych jest prawnie uzasadniony interes administratora (art. 6 ust. 1 lit. f RODO),',
          },
          {
            description:
              'prowadzenia i bieżącego zarządzania kontami aimedo.gift w mediach społecznościowych, w tym w celu komunikowania się ze społecznością oraz organizowania wydarzeń lub promocji na zasadach określonych funkcjonalnościami poszczególnych mediów społecznościowych oraz zgodnie z ich regulaminami. Podstawą prawną przetwarzania danych jest zgoda, np. w formie kliknięcia na wtyczkę „Lubię to”, (art. 6 ust. 1 lit a RODO).',
          },
        ],
      },

      {
        description:
          'Podanie danych osobowych Klienta jest dobrowolne, lecz niezbędne w celu wykonania usług w odpowiedni sposób. Klient ma możliwość niepodawania swoich danych osobowych i w takim przypadku usługi są świadczone z wyłączeniem użycia poczty elektronicznej.',
      },

      {
        description:
          'Dane osobowe Klientów będą przetwarzane przez Wydawcę przez okres ważności Bonu towarowego aimedo.gift, a po jego zakończeniu do czasu upływu okresu przedawnienia roszczeń z tytułu korzystania z Bonu towarowego aimedo.gift, bądź w razie powstania sporu do czasu wygaśnięcia roszczeń stron. Dane osobowe przetwarzane w celach pozyskiwania opinii przechowywane będą do czasu wniesienia sprzeciwu.',
      },

      {
        description:
          'Dane osobowe Klientów mogą być ujawniane przez Administratora podmiotom z nim współpracującym (odbiorcom danych), tj.:',
        children: [
          {
            description:
              'podmiotom dostarczającym i wspierającym systemy informatyczne stosowane przez Administratora w celu obsługi Serwisu aimedo.gift.',
          },
          {
            description:
              'podmiotom świadczącym usługi związane z działalnością Wydawcy w celu realizacji przez niego obowiązków wynikających z Transakcji – na podstawie umowy zawartej między Wydawcą a Klientem w szczególności w zakresie: obsługi Transakcji, obsługi Klienta (telefonicznej i e-mailowej), a także podmiotowi świadczącemu usługę lub dostarczającemu narzędzia do kontaktu telefonicznego i elektronicznego z Klientem.',
          },
          { description: 'podmiotom świadczącym usługi w zakresie zbierania opinii' },
          {
            description:
              'dostawcom mediów społecznościowych, w szczególności Facebook Ireland Ltd, na mocy regulaminów tych mediów społecznościowych oraz przy zapewnieniu stosowania przez ww. podmioty adekwatnych środków technicznych i organizacyjnych zapewniających ochronę danych, a także podmiotom świadczącym usługi zarządzania i konsultingu w zakresie kont Serwisu aimedo.gift w mediach społecznościowych, na mocy stosownych umów powierzenia przetwarzania danych osobowych oraz przy zapewnieniu stosowania przez ww. podmioty adekwatnych środków technicznych i organizacyjnych zapewniających ochronę danych.',
          },
        ],
      },
      {
        description:
          'Każdej osobie, której dane są przetwarzane, w zakresie wynikającym z przepisów prawa, przysługuje prawo dostępu do swoich danych oraz ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu wobec przetwarzania danych, które można wykonać poprzez wysłanie wiadomość e-mail na adres pomoc@aimedo.gift lub telefonicznie pod numerem tel.: 42 612 09 91.',
      },
      {
        description:
          'W przypadku wątpliwości związanych z przetwarzaniem danych osobowych każda osoba może zwrócić się do Administratora z prośbą o udzielenie informacji. Niezależnie od powyższego, każdemu przysługuje prawo wniesienia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych.',
      },
    ],
  },
];

export { newStatue, oldStatue };
