import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import head from 'lodash/head';
import isArray from 'lodash/isArray';

const errorHandler = (error) => {
  if (error.response.status === 500) return;
  if (typeof error.response.data === 'string') {
    return { [FORM_ERROR]: error.response.data };
  }
  const errors = error.response.data || {};
  const errorKey = isArray(errors) ? head(errors) : get(Object.keys(errors), '[0]', '');
  return { [FORM_ERROR]: errors[errorKey] || errorKey || 'We\'re sorry, our system encountered an error. Please contact support.' };
};

export default errorHandler;
