import React from 'react';
import { Box } from 'rebass';
import { colors } from '../../app/theme';

const HorizontalLine = ({ color = '#707070', opacity = 1, ...props }) => (
  <Box
    width={1}
    sx={{ borderBottom: `1px solid ${colors[color] || color}`, opacity }}
    {...props}
  />
);

export default HorizontalLine;
