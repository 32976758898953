import React from 'react';
import {
  Text, Flex, Box, Card, Image,
} from 'rebass';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import styled from '@emotion/styled';
import Loader from '../common/components/Loader';
import LayoutWithBanner from '../common/layouts/LayoutWithBanner';
import { colors } from '../app/theme';
import BaseContainer from '../common/layouts/BaseContainer';
import downloadFile from '../common/utils/fileDownload';

const CompanyImage = styled(Image)`
  max-width: 130px;
  max-height: 50px;
  object-fit: contain;
`;
const BorderedBox = styled(Box)`
  border: 1px solid #9b9b9b;
`;
const ListItemContainer = styled(Flex)`
  border-bottom: 1px solid #9b9b9b;
  :last-of-type {
    border-bottom: none;
  }
`;

@inject('orderStore')
@observer
class VoucherHistory extends React.Component {
  componentDidMount() {
    this._fetchHistory();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this._fetchHistory();
    }
  }

  _fetchHistory = () => {
    const { fetchHistory } = this.props.orderStore;
    const { orderId } = this.props;
    fetchHistory(orderId);
  };
  render() {
    const { data, isLoading } = this.props.orderStore.history;
    return (
      <LayoutWithBanner>
        <BaseContainer py={[40, 80, 150]}>
          <Card p={[20, 40, 60]} sx={{ maxWidth: 1061, border: `2px solid ${colors.green}` }} m="0 auto">
            <Text width={1} pb={20} fontSize={28} fontWeight={500} sx={{ borderBottom: '2px solid #9B9B9B' }}>
              Historia zakupionych bonów
            </Text>
            {isLoading && <Loader pt={37} />}
            {data.map((item, idx) => (
              <ListItemContainer pt={[10, 15, 40]} pb={[15, 20, 30]} key={`voucher-history-item-${idx}`} flexWrap="wrap">
                <Flex width={[0.5, 0.2]} flexDirection="column" alignItems="center" mb={[20, 0]}>
                  <Text fontSize={15} mb={20}>
                    Data:
                  </Text>
                  <Text fontSize={22}>{moment.utc(item.completionDate).format('DD.MM.YYYY')}</Text>
                </Flex>
                <Flex alignItems="center" width={[0.5, 0.2]} mb={[20, 0]} flexDirection="column">
                  <CompanyImage alignSelf="center" src={item.logoUrl} />
                  <Text fontSize={12}>{`Ważne do: ${moment.utc(item.validTo).format('DD.MM.YYYY')}`}</Text>
                </Flex>
                <Flex width={[0.33, 0.2]} flexDirection="column" alignItems="center" mb={[20, 0]}>
                  <Text fontSize={15} mb={20}>
                    Kupione
                  </Text>
                  <BorderedBox px={20} py={10} fontSize={22}>
                    {item.quantity}
                  </BorderedBox>
                </Flex>
                <Flex width={[0.33, 0.2]} flexDirection="column" alignItems="center" mb={[20, 0]}>
                  <Text fontSize={15} mb={20}>
                    Kwota
                  </Text>
                  <Text p={10} fontSize={22}>
                    {`${item.value} zł`}
                  </Text>
                </Flex>
                <Flex width={[0.33, 0.2]} flexDirection="column" alignItems="center" mb={[20, 0]}>
                  <Text fontSize={15} mb={20}>
                    Pobierz bon
                  </Text>
                  <Image
                    sx={{ cursor: 'pointer' }}
                    src="/images/file-icon.svg"
                    alt="Download file"
                    onClick={() => downloadFile(item.pdfUrl, `${moment.utc().valueOf()}.pdf`)}
                  />
                </Flex>
              </ListItemContainer>
            ))}
          </Card>
        </BaseContainer>
      </LayoutWithBanner>
    );
  }
}

export default VoucherHistory;
