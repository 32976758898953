import React from 'react';
import ga from '../../app/googleAnalytics';

export default class HistoryTracker extends React.Component {
  componentDidMount() {
    this._collectPageView();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this._collectPageView();
    }
  }

  _collectPageView = () => {
    if (ga) {
      ga.pageview(this.props.location.href);
    }
  };

  render() {
    return this.props.children;
  }
}
