import React from 'react';
import {
  Card, Text, Button, Flex, Image,
} from 'rebass';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { navigate } from '@reach/router';
import styled from '@emotion/styled';
import Steps from '../home/Steps';
import LayoutWithBanner from '../common/layouts/LayoutWithBanner';
import { colors } from '../app/theme';
import { APP_ROUTES } from '../app/routes';
import BaseContainer from '../common/layouts/BaseContainer';
import SummaryQuantityForm from './SummaryQuantityForm';

const CompanyImage = styled(Image)`
  max-width: 130px;
  max-height: 50px;
  object-fit: contain;
`;

const ListItemContainer = styled(Flex)`
  border-bottom: 1px solid #9b9b9b;
  justify-content: space-around;
`;

@inject('orderStore', 'offersStore')
@observer
class VouchersSummaryPage extends React.Component {
  async componentDidMount() {
    const { fetchOffers } = this.props.offersStore;
    const { fetchOrder } = this.props.orderStore;
    await fetchOffers();
    await fetchOrder();
  }

  render() {
    const {
      confirmOrder, summary, isOrderOverLimit, total, addVoucher, amountToSpare, isSubmitting,
    } = this.props.orderStore;
    return (
      <LayoutWithBanner>
        <BaseContainer pt={[20, 40, 69]}>
          <Steps activeStep={2} />
          <Card
            p={[30, 30, 64]}
            mb={[20, 20, 37]}
            sx={{
              border: `2px solid ${colors.green}`,
            }}
          >
            <Text
              fontSize={29}
              fontWeight={600}
              pb={30}
              sx={{
                borderBottom: '2px solid',
              }}
            >
              Podsumowanie
            </Text>
            {summary.map(item => (
              <ListItemContainer key={item.voucherId} pt={[10, 15, 40]} pb={[15, 20, 30]} flexWrap="wrap">
                <Flex width={[1, 1 / 4]} alignItems="center" mb={[10, 0]} flexDirection="column">
                  <CompanyImage alignSelf="center" src={item.offer.logoUrl} mb={10} />
                  <Text fontSize={12}>{`Ważne do: ${moment.utc(item.validTo).format('DD.MM.YYYY')}`}</Text>
                </Flex>
                <Flex width={[1 / 3, 1 / 4]} flexDirection="column" alignItems="center">
                  <Text fontSize={15} mb={20}>
                    Kwota
                  </Text>
                  <Text p={10} fontSize={22}>
                    {`${(item.total / item.quantity) || 0} zł`}
                  </Text>
                </Flex>
                <Flex width={[1 / 3, 1 / 4]} flexDirection="column" alignItems="center">
                  <Text fontSize={15} mb={20}>
                    Ilość
                  </Text>
                  <SummaryQuantityForm
                    offer={item.offer}
                    initialValues={{ voucherId: item.voucherId, quantity: item.quantity }}
                    onSubmit={(values => addVoucher(item.offer, values, false))}
                  />
                </Flex>
                <Flex width={[1 / 3, 1 / 4]} flexDirection="column" alignItems="center">
                  <Text fontSize={15} mb={20}>
                    Suma
                  </Text>
                  <Text p={10} fontSize={22}>
                    {`${item.total} zł`}
                  </Text>
                </Flex>
              </ListItemContainer>
            ))}
            <Flex flexDirection={['column', 'row', 'row']} justifyContent="flex-end">
              <Flex flexDirection="column" fontSize={26} mt={[20, 40]}>
                <Text mb={20} textAlign="right">
                  {`Łączna wartość wybranych bonów: ${total} zł`}
                </Text>
                <Text textAlign="right" color="green">{`Pozostało do wykorzystania: ${amountToSpare} zł`}</Text>
              </Flex>
            </Flex>
          </Card>
          <Flex justifyContent="space-between" pb={[20, 40]}>
            <Button variant="secondary" onClick={() => navigate(APP_ROUTES.ROOT)}>
              Wróć do wyboru
            </Button>
            <Button disabled={isOrderOverLimit || total === 0 || isSubmitting} onClick={confirmOrder}>Dalej</Button>
          </Flex>
        </BaseContainer>
      </LayoutWithBanner>
    );
  }
}

export default VouchersSummaryPage;
