import theme from '@rebass/preset';

export const colors = {
  white: '#FFF',
  darkGreen: '#6D9700',
  green: '#92D400',
  lightGray: '#AAAAAA',
  lighterGray: '#F5F5F5',
  gray: '#545555',
  darkGray: '#9B9B9B',
  red: '#FF0201',
};

export default {
  ...theme,
  breakpoints: ['640px', '960px', '1200px'],
  colors: {
    ...theme.colors,
    ...colors,
  },
  buttons: {
    ...theme.buttons,
    primary: {
      ...theme.buttons.primary,
      cursor: 'pointer',
      backgroundColor: colors.green,
      color: colors.white,
      borderRadius: '0px',
      minWidth: 150,
      fontWeight: 500,
      padding: '17px 20px',
      '&:disabled': {
        backgroundColor: colors.lightGray,
      },
    },
    secondary: {
      ...theme.buttons.primary,
      cursor: 'pointer',
      backgroundColor: colors.lightGray,
      transition: 'all .3s ease',
      padding: '17px 20px',
      color: colors.white,
      borderRadius: '0px',
      minWidth: 150,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: colors.green,
      },
    },
    outline: {
      ...theme.buttons.outline,
      cursor: 'pointer',
      backgroundColor: colors.green,
      color: colors.white,
      borderRadius: '0px',
      border: `1px solid ${colors.white}`,
      fontWeight: 500,
      minWidth: 150,
      ':hover': {
        textDecoration: 'underline',
      },
    },
  },
  variants: {
    ...theme.variants,
    card: {
      ...theme.variants.card,
      borderRadius: 7,
      boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)',
    },
  },
  fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Comfortaa', cursive",
  },
};
