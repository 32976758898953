import React from 'react';
// import qs from 'query-string';
import {
  Text, Flex, Card, Button,
} from 'rebass';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import BaseContainer from '../common/layouts/BaseContainer';
import Loader from '../common/components/Loader';
import OfferCard from './OfferCard';
import BrandsCarousel from './BrandsCarousel';
import BonDetailsOverlay from './BonDetailsOverlay';
import Steps from './Steps';
import LayoutWithBanner from '../common/layouts/LayoutWithBanner';
// import DiscountCard from './DiscountCard';
// import DiscountDetailsOverlay from './DiscountDetailsOverlay';
import { colors } from '../app/theme';
// import Pagination from '../common/components/Pagination';

@inject('offersStore', 'orderStore', 'discountsStore', 'userStore')
@observer
class HomePage extends React.Component {
  @observable isOpen = false;

  @observable isDiscountOpen = false;

  @observable voucherDetails = {};

  async componentDidMount() {
    // const { location: { search } } = this.props;
    const { fetchOffers } = this.props.offersStore;
    const { fetchOrder } = this.props.orderStore;
    // const { fetchDiscounts } = this.props.discountsStore;
    // const { page = 1 } = qs.parse(search);
    // fetchDiscounts(page);
    await fetchOffers();
    await fetchOrder();
  }

  // onPageChange = (page) => {
  //   const { navigate } = this.props;
  //   navigate(`?page=${page}`);
  //   const {
  //     discountsStore: { fetchDiscounts },
  //   } = this.props;
  //   fetchDiscounts(page);
  // };

  render() {
    const { isLoading, data } = this.props.offersStore.offers;
    const {
      addVoucher, createOrder, vouchers, total, isOrderOverLimit, amountToSpare, isSubmitting,
    } = this.props.orderStore;
    // const {
    //   isLoading: isLoadingDiscounts, paging, discounts, discount, fetchDiscountById, clearDiscount, isLoadingSingle,
    // } = this.props.discountsStore;
    const { isActive } = this.props.userStore;
    // const { page = 1 } = qs.parse(this.props.location.search);

    return (
      <>
        <LayoutWithBanner>
          <BaseContainer p={[20, 40, 69]}>
            <Steps activeStep={1} />
            <Flex flexDirection="column">
              {isLoading && <Loader mb={[15, 15, 15, 32]} py="70px" />}
              {!isLoading && isActive && (
                <Flex flexWrap="wrap" mx={[-10, -10, -10, -37]} mb={[15, 15, 15, 32]}>
                  {data.map(offer => (
                    <OfferCard
                      isSelected={!!(vouchers[offer.code])}
                      key={offer.code}
                      initialValues={vouchers[offer.code] || {}}
                      offer={offer}
                      findOutMore={(voucher) => {
                        this.isOpen = true;
                        this.voucherDetails = voucher;
                      }}
                      addVoucher={addVoucher}
                    />
                  ))}
                </Flex>
              )}
              {isActive && (
                <Card px={[30, 30, 79]} py={[30, 45, 45]} mb={[40, 40, 69]}>
                  <Flex flexDirection={['column', 'row', 'row']} alignItems="center">
                    <Flex flexDirection="column" mr="auto" fontSize={26} mb={[40, 0]}>
                      <Text mb={[20, 0, 0]} color={!isOrderOverLimit ? colors.gray : colors.red}>
                        {`Wybrałeś bony o łącznej wartości: ${total} zł`}
                      </Text>
                      <Text color="green">
                        {`Pozostało do wykorzystania: ${amountToSpare} zł`}
                      </Text>
                    </Flex>
                    <Button disabled={isOrderOverLimit || total === 0 || isSubmitting} onClick={createOrder}>
                      DALEJ
                    </Button>
                  </Flex>
                </Card>
              )}
              {!isLoading && !isActive && (
                <Text fontSize={32} my={[30, 60, 90]} textAlign="center">
                  Upłynął termin ważności Twojej karty. Twoja karta jest już nieaktywna.
                </Text>
              )}
              {/* {!isEmpty(discounts) && (
                <Text mt={40} mb={20} fontSize={44}>Kody rabatowe</Text>
              )} */}
              {/* {isLoadingDiscounts && <Loader mb={[15, 15, 15, 32]} py="70px" />} */}
              {/* {isEmpty(discounts) && !isLoadingDiscounts && (
                <Text fontSize={32} my={[30, 60, 90]} textAlign="center">
                  {isActive ? 'Przepraszamy, ale lista kodów jest pusta' : 'Upłynął termin ważności Twojej karty. Twoja karta jest już nieaktywna.'}
                </Text>
              )} */}
              {/* {!isLoadingDiscounts && (
                <Flex
                  flexWrap="wrap"
                  mx={[-10, -10, -10, -37]}
                  mb={[15, 15, 15, 32]}
                >
                  {map(discounts, d => (
                    <DiscountCard
                      key={`discount-card-key-${d.id}`}
                      {...d}
                      findOutMore={() => {
                        this.isDiscountOpen = true;
                        fetchDiscountById(d, () => {
                          this.isDiscountOpen = false;
                        });
                      }}
                      isLoading={isLoadingSingle}
                    />
                  ))}
                </Flex>
              )} */}
              {/* <Box pb={60} mx="auto">
                <Pagination forcePage={+page - 1} pageCount={paging.pages} onPageChange={this.onPageChange} />
              </Box> */}
              {isActive && (
                <BrandsCarousel items={this.props.offersStore.carouselItems} />
              )}
            </Flex>
          </BaseContainer>
        </LayoutWithBanner>
        {this.isOpen && (
          <BonDetailsOverlay onClose={() => { this.isOpen = false; }} details={this.voucherDetails} />
        )}
        {/* {this.isDiscountOpen && (
          <DiscountDetailsOverlay
            onClose={() => {
              this.isDiscountOpen = false;
              clearDiscount();
            }}
            isLoading={isLoadingSingle}
            discount={discount}
          />
        )} */}
      </>
    );
  }
}

export default HomePage;
