import React from 'react';
import { Flex, Text, Box } from 'rebass';
import BaseContainer from '../common/layouts/BaseContainer';
import { newStatue, oldStatue } from './index';

const Statue = ({ subheader, statue }) => (
  <Flex width={1} flexDirection="column" mb={4}>
    <Flex width={1} alignItems="center" flexDirection="column">
      <Text fontWeight="bold" fontSize={24}>
          Regulamin bonu aimedo.gift
      </Text>
      <Text fontSize={20} mt={10} mb={20}>
        {subheader}
      </Text>
    </Flex>
    {statue.map(({ title, subtitle, list }, contentIdx) => (
      <Flex key={`content-${contentIdx}`} flexDirection="column" width={1}>
        <Text width={1} textAlign="center" fontWeight="bold" my={15}>
          {title}
        </Text>
        {subtitle && <Text my={15}>{subtitle}</Text>}
        <ol style={{ listStyle: 'decimal' }}>
          {list.map(({ description, children }, idx) => (
            <Text lineHeight={1.2} as="li" mb="5px" key={`${title}-${idx}`}>
              {description}
              <Box as="ul" sx={{ listStyle: 'circle' }} ml={20}>
                {(children || []).map((subItem, i) => (
                  <Text lineHeight={1.2} mb="5px" as="li" key={`subitem-desc-${idx}-${title}-${i}`}>
                    {subItem.description}
                  </Text>
                ))}
              </Box>
            </Text>
          ))}
        </ol>
      </Flex>
    ))}
  </Flex>
);
class StatuePage extends React.Component {
  render() {
    return (
      <BaseContainer my={50} px={[20, 35]}>
        <Statue subheader="Obowiązuje od 18 kwietnia 2023 roku" statue={newStatue} />
        <Statue subheader="Obowiązuje od 12 grudnia 2019 roku" statue={oldStatue} />
      </BaseContainer>
    );
  }
}

export default StatuePage;
