import validator from 'validator';
import isNaN from 'lodash/isNaN';
import moment from 'moment';

export const composeValidators = (...validators) => (value, values) => validators.reduce((error, _validator) => error || _validator(value, values), undefined);

export const required = value => (value ? undefined : 'To pole jest wymagane');

export const mustBeValidEmail = value => (validator.isEmail(value) ? undefined : 'Podany adres email jest nieprawidłowy.');

export const passwordsMustMatch = (value, allValues) => (value === allValues.password ? undefined : 'Password confirmation must match');

export const passwordLength = length => (value = '') => (value === '' || value.length >= length ? undefined : `Password needs to be ${length} characters or longer`);

export const maxLength = length => (value = '') => (value === '' || value.length <= length ? undefined : `Search phrase needs to be ${length} characters or shorter`);

export const mustBeValidDate = value => (moment(value).isValid() ? undefined : 'Invalid date');

export const mustBeAfterDate = min => value => (moment(value).isSameOrAfter(min) ? undefined : `Must be after ${moment(min).format('DD/MM/YYYY')}.`);

export const minValue = min => value => (isNaN(value) || value >= min ? undefined : `Should be greater than or equal ${min}`);

export const maxValue = max => value => (isNaN(value) || value <= max ? undefined : `Should be less than or equal to ${max}`);
