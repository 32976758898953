import React from 'react';
import { Flex, Text, Box } from 'rebass';
import BaseContainer from '../common/layouts/BaseContainer';
import content from './index';

class PrivacyPolicyPage extends React.Component {
  render() {
    return (
      <BaseContainer my={50} px={[20, 35]}>
        <Flex width={1} flexDirection="column">
          <Flex width={1} alignItems="center" flexDirection="column">
            <Text fontWeight="bold" fontSize={24} mb={24}>
              Informacja o ochronie danych osobowych
            </Text>
          </Flex>
          <Box as="ol" sx={{ listStyle: 'decimal' }} ml={20}>
            {content.map(({ description, children }, idx) => (
              <Text lineHeight={1.2} as="li" mb="5px" key={`title-${idx}`}>
                {description}
                <Box as="ol" sx={{ listStyle: 'circle' }} ml={20}>
                  {(children || []).map((subItem, i) => (
                    <Text lineHeight={1.2} mb="5px" as="li" key={`subitem-desc-${idx}-${i}`}>
                      {subItem.description}
                    </Text>
                  ))}
                </Box>
              </Text>
            ))}
          </Box>
        </Flex>
      </BaseContainer>
    );
  }
}

export default PrivacyPolicyPage;
