import { navigate } from '@reach/router';
import { get } from 'lodash';
import { action, observable } from 'mobx';
import { toast } from 'react-toastify';
import API from '../app/api';
import { API_ROUTES } from '../app/routes';

export class DiscountsStore {
  @observable discounts = [];

  @observable discount = {};

  @observable paging = {};

  @observable isLoading = false;

  @observable isLoadingSingle = false;

  @action fetchDiscounts = async (page = 1) => {
    try {
      this.isLoading = true;
      const {
        data: {
          data,
          meta: { pagination },
        },
      } = await API(`${API_ROUTES.DISCOUNTS}?page=${page}&page_size=12`);
      this.discounts = data;
      this.paging = pagination;
    } catch (e) {
      this.discounts = [];
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchDiscountById = async (discount, errorCB) => {
    try {
      this.isLoadingSingle = true;
      if (discount.codeKind === 'only_link') {
        this.discount = { ...discount };
      } else {
        const { data } = await API.put(API_ROUTES.DISCOUNTS_CODE(discount.id));
        this.discount = { ...discount, ...data };
      }
    } catch (e) {
      if (get(e, 'response.status') === 405) {
        toast.error('Przepraszamy, ale ten kod niestety już wygasł.');
        if (+this.paging.currentPage === 1) {
          this.fetchDiscounts(1);
        } else {
          navigate('/?page=1');
        }
      }
      if (typeof errorCB === 'function') {
        errorCB();
      }
    } finally {
      this.isLoadingSingle = false;
    }
  };

  @action clearDiscount = () => {
    this.discount = {};
  };
}

export default new DiscountsStore();
