import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    font-family: 'Poppins', sans-serif;
    color: #545555;
  }
  strong {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  small {
    font-size: smaller;
  }
  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  sup {
    vertical-align: super;
    font-size: smaller;
  }
  ins {
    text-decoration: underline;
  }
  del {
    text-decoration: line-through;
  }
  mark {
    background-color: yellow;
    color: black;
  }
  a {
    color: black;
  }
  .wyswig {
    p {
      margin-bottom: 8px;
    }
  }
`;

export default Global;
