import React, { Component } from 'react';
import {
  withGoogleMap, GoogleMap, Marker,
} from 'react-google-maps';
import { inject, observer } from 'mobx-react';

@inject('mapStore')
@observer
class Map extends Component {
  render() {
    const { data } = this.props.mapStore.locations;
    const { center, zoom } = this.props;
    return (
      <GoogleMap
        center={center || { lat: 51.759445, lng: 19.457216 }}
        zoom={zoom || 5.9}
      >
        {data.map(location => (
          <Marker key={location.seller} position={{ lat: location.latitude, lng: location.longitude }} />
        ))}
      </GoogleMap>
    );
  }
}

export default withGoogleMap(Map);
