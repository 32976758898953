import { computed, action, observable } from 'mobx';
import API from '../app/api';
import { API_ROUTES } from '../app/routes';
import errorHandler from '../common/utils/errorHandler';

export class UserStore {
  @observable profile = {
    isLoading: false,
    data: {},
  };
  @action fetchProfile = async () => {
    this.profile.isLoading = true;
    try {
      const { data } = await API(API_ROUTES.PROFILE);
      this.profile.data = data;
    } catch (e) {
      return errorHandler(e);
    } finally {
      this.profile.isLoading = false;
    }
  };

  @action resetProfile = () => {
    this.profile.data = {};
  };

  @computed get isActive() {
    return this.profile.data.active;
  }
}

export default new UserStore();
