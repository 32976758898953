import React from 'react';
import {
  Flex, Box, Card, Image,
} from 'rebass';
import Slider from 'react-slick';
import includes from 'lodash/includes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Image style={{ ...style, right: -25 }} className={className} src="/images/next.svg" width={24} onClick={onClick} />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Image style={{ ...style, left: -25 }} className={className} src="/images/prev.svg" width={24} onClick={onClick} />
  );
}

class BrandsCarousel extends React.Component {
  getSettings = () => {
    const itemsLength = this.props.items.length;
    return {
      infinite: true,
      speed: 500,
      slidesToShow: itemsLength <= 5 ? itemsLength - 1 : 5,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: itemsLength <= 3 ? itemsLength - 1 : 3,
            slidesToScroll: 1,
            infinite: true,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: itemsLength <= 2 ? itemsLength - 1 : 2,
            slidesToScroll: 1,
            initialSlide: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    };
  };

  render() {
    const { items } = this.props;
    return (
      <Box mx={-10}>
        <Slider {...this.getSettings()}>
          {items.length > 1 && items.map(item => (
            <Box key={item.carouselPosition} p={10}>
              <Card p={[15, 30]} sx={{ height: 120 }}>
                <Flex alignItems="center" justifyContent="center" height="100%">
                  <a href={includes(item.carouselUrl, 'http') ? item.carouselUrl : `http://${item.carouselUrl}`} rel="noopener noreferrer" target="_blank">
                    <Image sx={{ maxHeight: 60, height: 'auto' }} src={item.logoUrl} />
                  </a>
                </Flex>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>
    );
  }
}

export default BrandsCarousel;
