import React from 'react';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import NavBar from '../components/navigation/NavBar';
import Footer from '../components/Footer';
import { APP_ROUTES } from '../../app/routes';
// import CookiePolicy from '../components/CookiePolicy';

const FOOTERLESS_ROUTES = [APP_ROUTES.LOGIN];

const Wrapper = styled(Flex)`
  padding-top: 80px;
  padding-bottom: ${props => (props.isFooterHidden ? '0px' : '230px')};
  position: relative;
  min-height: 100vh;
`;

class BaseLayout extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo({ top: 0 });
    }
  }

  render() {
    const { pathname } = this.props.location;
    const isFooterHidden = !!FOOTERLESS_ROUTES.find(footerlessRoute => footerlessRoute === pathname);
    const { children, location, ...rest } = this.props;
    return (
      <Wrapper isFooterHidden={isFooterHidden}>
        <NavBar location={this.props.location} />
        <Box width={1} {...rest}>
          {children}
        </Box>
        {!isFooterHidden && <Footer />}
        {/* <CookiePolicy /> */}
      </Wrapper>
    );
  }
}

export default BaseLayout;
