import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { Flex, Box } from 'rebass';
import Select from '../common/form/Select';
import Stepper from '../common/form/Stepper';

const OfferForm = ({
  offer, options = [], onSubmit, initialValues,
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    render={({ handleSubmit, values, form: { reset } }) => (
      <form onSubmit={handleSubmit}>
        <FormSpy subscription={{ submitError: true }} onChange={() => reset()} />
        <Flex alignItems="flex-end">
          <Field
            label="Wybierz kwotę"
            name="voucherId"
            component={Select}
            options={options}
            afterChange={handleSubmit}
          />
          <Box mx={[10, 10, 10, 20]} />
          <Field
            label="Wybierz ilość"
            name="quantity"
            max={((offer.vouchers || []).find(v => v.id === +(values || {}).voucherId) || {}).availableCodesAmount}
            component={Stepper}
            afterChange={handleSubmit}
          />
        </Flex>
      </form>
    )}
  />
);

export default OfferForm;
