export const APP_ROUTES = {
  ROOT: '/',
  VOUCHERS_SUMMARY: '/vouchers-summary',
  VOUCHERS_REDEEM: orderId => (orderId ? `/vouchers-redeem/${orderId}` : '/vouchers-redeem/:orderId'),
  LOGIN: '/login',
  MAP: '/map',
  VOUCHER_HISTORY: '/voucher-history',
  VOUCHER_HISTORY_BY_ORDER_ID: orderId => (orderId ? `/voucher-history/${orderId}` : '/voucher-history/:orderId'),
  PDF: '/pdf/:pdf',
  COOKIE_POLICY: '/cookie-policy',
  PRIVACY_POLICY: '/privacy-policy',
  STATUTE: '/statue',
  CONTACT: '/contact',
  GIFT: '/zyczenia',
};

export const API_ROUTES = {
  LOGIN: '/auth/login',
  OFFER: '/shops/offer',
  ORDER: '/orders',
  ORDER_CONFIRM: '/orders/confirm',
  HISTORY: '/orders/history',
  HISTORY_BY_ORDER_ID: orderId => `/orders/${orderId}/history`,
  PROFILE: '/profile',
  ORDER_PDF: orderId => `/pdf/${orderId}.pdf`,
  SEND_ORDER_PDF_TO_EMAIL: orderId => `/orders/${orderId}/email`,
  PHARMACIES: '/pharmacies',
  DISCOUNTS: '/discount/coupons',
  DISCOUNTS_CODE: discountId => `/discount/coupons/${discountId}/get_code`,
};
