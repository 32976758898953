import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { ThemeProvider } from 'emotion-theming';
import { Provider } from 'mobx-react';
import React from 'react';
import { render } from 'react-dom';
import { Normalize } from 'styled-normalize';
import { Reset } from 'styled-reset';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tippy/dist/tippy.css';

import App from './App';
import theme from './app/theme';
import Global from './Global';
import * as stores from './stores';

render(
  <>
    <Reset />
    <Normalize />
    <Global />
    <ToastContainer />
    <Provider {...stores}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </>,
  document.getElementById('root'),
);
