import { toast } from 'react-toastify';
import API from '../../app/api';

let isLoading = false;

const downloadFile = async (url, fileName) => {
  if (isLoading) return;
  isLoading = true;
  try {
    const { data } = await API(url, { responseType: 'blob' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(new Blob([data]), fileName);
    } else {
      const fileURL = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (e) {
    toast.error('Nie mozna pobrać pliku.');
    console.log(e);
  } finally {
    isLoading = false;
  }
};

export default downloadFile;
