import styled from '@emotion/styled';
import { Label, Input as RebassInput } from '@rebass/forms';
import React from 'react';
import { Box, Image } from 'rebass';
import Error from './Error';

const StyledInput = styled(RebassInput)`
  border-radius: 0px;
  border: 0 none;
  border-bottom: 1px solid
    ${props => props.theme.colors.charcoalGrayHalfOpacity};
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500 !important;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid ${props => props.theme.colors.charcoalGray};
  }

  &:focus ~ label {
    top: -20px;
    font-size: 13px;
  }

  &:-internal-autofill-selected ~ label {
    top: -20px !important;
    font-size: 13px !important;
  }

  ${props => props.value &&
    `
      & ~ label {
        top: -20px;
        font-size: 13px;
      }
    `}
`;

const Input = ({
  input, label, meta, icon, ...props
}) => (
  <Box width={1} mb={30} mt={10} sx={{ position: 'relative' }}>
    <StyledInput pl={0} py={10} pr={icon ? '20px' : 0} {...props} {...input} />
    {icon && (
      <Image
        src={icon}
        width={14}
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translateY(-50%)',
        }}
      />
    )}
    <Label
      px={0}
      py={10}
      htmlFor={input.name}
      color="charcoalGray"
      sx={{
        position: 'absolute',
        pointerEvents: 'none',
        transition: '.3s ease all',
        top: 0,
        opacity: 0.5,
      }}
    >
      {label}
    </Label>
    <Error meta={meta} />
  </Box>
);

export default Input;
