import React from 'react';
import { Field, Form } from 'react-final-form';
import { Flex, Text } from 'rebass';
import Input from '../common/form/Input';
import SubmitButton from '../common/form/SubmitButton';
import SubmitError from '../common/form/SubmitError';
import { composeValidators, required, mustBeValidEmail } from '../common/utils/formValidators';
import Checkbox from '../common/form/Checkbox';

const SendVouchersForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Flex flexDirection="column" alignItems="flex-start">
          <Text fontSize={20}>
            Wprowadź adres email, na który wyślemy bony
          </Text>
          <SubmitError />
          <Field
            label="Podaj adres email"
            name="email"
            component={Input}
            validate={composeValidators(required, mustBeValidEmail)}
          />
          <Field
            type="checkbox"
            label="Potwierdzam poprawność podanego adresu email"
            name="acceptance"
            component={Checkbox}
            validate={composeValidators(required)}
          />
          <SubmitButton mt={40} py={17}>WYŚLIJ</SubmitButton>
        </Flex>
      </form>
    )}
  />
);

export default SendVouchersForm;
