import React from 'react';
import {
  Box, Card, Text,
} from 'rebass';
import HorizontalLine from '../common/components/HorizontalLine';
import { colors } from '../app/theme';
import OfferForm from './OfferForm';

class OfferCard extends React.Component {
  getOptions = () => {
    const { vouchers = [] } = this.props.offer;
    return vouchers.map(voucher => ({
      label: `${voucher.value} zł`,
      value: voucher.id,
    }));
  }

  render() {
    const {
      offer, addVoucher, findOutMore, isSelected, initialValues,
    } = this.props;
    return (
      <Box width={[1, 1 / 2, 1 / 3]} px={[10, 10, 15, 37]} pb={[20, 20, 20, 37]} key={offer.id}>
        <Card
          p={[15, 20, 15, 30]}
          sx={{
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: isSelected ? colors.green : 'transparent',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: 200,
              height: 80,
              backgroundImage: `url(${offer.logoUrl})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            mx="auto"
            mb={30}
          />
          <Text fontSize={15} mb="5px" sx={{ minHeight: 30 }}>
            {offer.name}
          </Text>
          <Text color="darkGreen" fontSize={15} sx={{ cursor: 'pointer' }} onClick={() => findOutMore(offer)}>
            Dowiedz się więcej
          </Text>
          <HorizontalLine my={30} opacity={0.19} />
          <OfferForm
            offer={offer}
            options={this.getOptions()}
            onSubmit={values => addVoucher(offer, values)}
            initialValues={initialValues}
          />
        </Card>
      </Box>
    );
  }
}

export default OfferCard;
