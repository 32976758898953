import { Label, Select as RebassSelect } from '@rebass/forms';
import React from 'react';
import { Box } from 'rebass';
import Error from './Error';

const Select = ({
  input, label, options = [], afterChange, ...props
}) => (
  <Box width={1}>
    {label && (
      <Label mb="5px" fontSize={15} htmlFor={input.name}>
        {label}
      </Label>
    )}
    <RebassSelect
      {...input}
      {...props}
      onChange={(e) => {
        input.onChange(e);
        if (typeof afterChange === 'function') {
          afterChange(input.value);
        }
      }}
    >
      <option value="" />
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </RebassSelect>
    <Error name={input.name} />
  </Box>
);

export default Select;
