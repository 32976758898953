import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import qs from 'query-string';
import { navigate } from '@reach/router';
import styled from '@emotion/styled';
import {
  Box, Flex, Image, Text, Card,
} from 'rebass';
import LoginForm from './LoginForm';
import { APP_ROUTES } from '../app/routes';

const AttenntionContainer = styled(Flex)`
  max-width: 700px;
  text-align: center;
`;

@inject('authStore')
@observer
class LoginPage extends Component {
  componentDidMount() {
    if (this.props.authStore.isAuthenticated) {
      setTimeout(() => { // without timeout root page doesnt show up
        navigate(APP_ROUTES.ROOT);
      }, 0);
    }
  }
  render() {
    const { signIn } = this.props.authStore;
    const { redirect } = qs.parse(this.props.location.search);
    return (
      <Flex alignItems="center" flexDirection="column" mt={[30, 50, 100]}>
        <Card mx={15} px={[15, 50, 100]} py={[40, 60, 90]}>
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Box width={[1, 0.45]} order={[2, 1]} maxWidth="331px">
              <Text fontSize={28} mb={20}>
                Wejdź do świata aimedo.gift!
              </Text>
              <LoginForm onSubmit={values => signIn({ ...values, redirect })} />
            </Box>
            <Flex width={[1, 0.45]} order={[1, 2]} justifyContent="center">
              <Image mt={[0, 50]} mb={[15, 0]} sx={{ maxHeight: 220 }} src="/images/card.svg" />
            </Flex>
          </Flex>
        </Card>
        <AttenntionContainer px={15} mt={35} mb={30} justifyContent="center" flexDirection="column">
          <Flex width={1} flexWrap={['wrap', 'nowrap']}>
            <Text color="red" width={[1, 70]} mr="5px" textAlign="center">
              Uwaga!
            </Text>
            <Text width={[1, 'auto']}>Pamiętaj, nie udostępniaj nikomu numeru karty ani kodu CVV.</Text>
          </Flex>
          <Text>W przypadku problemów z zalogowaniem się, skontaktuj się z nami:</Text>
          <Text>telefonicznie pod numerem 42 612 09 91 lub mailowo: pomoc@aimedo.gift</Text>
        </AttenntionContainer>
      </Flex>
    );
  }
}

export default LoginPage;
