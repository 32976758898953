import React from 'react';
import { FormSpy } from 'react-final-form';
import { Text } from 'rebass';

const SubmitError = () => (
  <FormSpy subscription={{ submitError: true }}>
    {({ submitError }) => <Text mb={20} color="red">{submitError}</Text>}
  </FormSpy>
);

export default SubmitError;
